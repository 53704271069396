declare global {
  interface Window {
    clarity: any
  }
}

/**
 * this function identifies the user when they log in
 * @param email - user email
 * @param count - number of times the user has logged in
 */
const identifyClarity = async (email: string, count: number = 0) => {
  //@ts-ignore
  if (process.env.NODE_ENV === 'development') return
  if (!window.clarity) {
    if (count < 20) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      await identifyClarity(email, count + 1)
    }
  } else {
    window.clarity('identify', email)
    window.clarity('event', 'login')
  }
}

export default { identifyClarity }
