import { LANGUAGEMETA } from '@/utils/meta'

import ADA_dark from '@/assets/images/code/ADA_dark.svg'
import AWK from '@/assets/images/code/AWK.svg'
import AWK_dark from '@/assets/images/code/AWK_dark.svg'
import Ada from '@/assets/images/code/Ada.svg'
import Algol68 from '@/assets/images/code/Algol68.svg'
import AssemblerFASM from '@/assets/images/code/AssemblerFASM.svg'
import AssemblerGCC from '@/assets/images/code/AssemblerGCC.svg'
import AssemblerNASM from '@/assets/images/code/AssemblerNASM.svg'
import BC from '@/assets/images/code/BC.svg'
import Bash from '@/assets/images/code/Bash.svg'
import Befunge from '@/assets/images/code/Befunge.svg'
import Blockly from '@/assets/images/code/Blockly.svg'
import Brainf__k from '@/assets/images/code/Brainf__k.svg'
import Brainf__k_dark from '@/assets/images/code/Brainf__k_dark.svg'
import C from '@/assets/images/code/C.svg'
import Clojure from '@/assets/images/code/Clojure.svg'
import Cobol from '@/assets/images/code/Cobol.svg'
import CoffeeScript from '@/assets/images/code/CoffeeScript.svg'
import Cpp from '@/assets/images/code/Cpp.svg'
import Csharp from '@/assets/images/code/Csharp.svg'
import D from '@/assets/images/code/D.svg'
import Dart from '@/assets/images/code/Dart.svg'
import Elixir from '@/assets/images/code/Elixir.svg'
import Erlang from '@/assets/images/code/Erlang.svg'
import FSharp from '@/assets/images/code/FSharp.svg'
import Factor from '@/assets/images/code/Factor.svg'
import Falcon from '@/assets/images/code/Falcon.svg'
import Fantom from '@/assets/images/code/Fantom.svg'
import Forth from '@/assets/images/code/Forth.svg'
import Forth_dark from '@/assets/images/code/Forth_dark.svg'
import Fortran from '@/assets/images/code/Fortran.svg'
import FreeBasic from '@/assets/images/code/FreeBasic.svg'
import FreeBasic_dark from '@/assets/images/code/FreeBasic_dark.svg'
import Go from '@/assets/images/code/Go.svg'
import Groovy from '@/assets/images/code/Groovy.svg'
import HTML_CSS_Javascript from '@/assets/images/code/HTML_CSS_Javascript.svg'
import Hack from '@/assets/images/code/Hack.svg'
import Haskell from '@/assets/images/code/Haskell.svg'
import Haxe from '@/assets/images/code/Haxe.svg'
import IconLogo from '@/assets/images/code/Icon.svg'
import Intercal from '@/assets/images/code/Intercal.svg'
import JBang from '@/assets/images/code/JBang.svg'
import JLang from '@/assets/images/code/JLang.svg'
import Java from '@/assets/images/code/Java.svg'
import Kotlin from '@/assets/images/code/Kotlin.svg'
import LOLCODE from '@/assets/images/code/LOLCODE.svg'
import Lisp from '@/assets/images/code/Lisp.svg'
import Lua from '@/assets/images/code/Lua.svg'
import Mozart from '@/assets/images/code/Mozart.svg'
import MySQL from '@/assets/images/code/MySQL.svg'
import Nemerle from '@/assets/images/code/Nemerle.svg'
import Nim from '@/assets/images/code/Nim.svg'
import NodeJS from '@/assets/images/code/NodeJS.svg'
import OCTAVE_Matlab from '@/assets/images/code/OCTAVE_Matlab.svg'
import ObjectiveC from '@/assets/images/code/ObjectiveC.svg'
import Ocaml from '@/assets/images/code/Ocaml.svg'
import PHP from '@/assets/images/code/PHP.svg'
import Pascal from '@/assets/images/code/Pascal.svg'
import Perl from '@/assets/images/code/Perl.svg'
import Picolisp from '@/assets/images/code/Picolisp.svg'
import Pike from '@/assets/images/code/Pike.svg'
import Pike_dark from '@/assets/images/code/Pike_dark.svg'
import Prolog from '@/assets/images/code/Prolog.svg'
import Python2 from '@/assets/images/code/Python2.svg'
import R from '@/assets/images/code/R.svg'
import Rhino from '@/assets/images/code/Rhino.svg'
import Ruby from '@/assets/images/code/Ruby.svg'
import Rust from '@/assets/images/code/Rust.svg'
import Rust_dark from '@/assets/images/code/Rust_dark.svg'
import SQL from '@/assets/images/code/SQL.svg'
import Scala from '@/assets/images/code/Scala.svg'
import Scheme from '@/assets/images/code/Scheme.svg'
import Scheme_dark from '@/assets/images/code/Scheme_dark.svg'
import SmallTalk from '@/assets/images/code/SmallTalk.svg'
import SpiderMonkey from '@/assets/images/code/SpiderMonkey.svg'
import Swift from '@/assets/images/code/Swift.svg'
import Swift_dark from '@/assets/images/code/Swift_dark.svg'
import TCL from '@/assets/images/code/TCL.svg'
import Unlambda from '@/assets/images/code/Unlambda.svg'
import VB from '@/assets/images/code/VB.svg'
import Verilog from '@/assets/images/code/Verilog.svg'
import Whitespace from '@/assets/images/code/Whitespace.svg'
import Yabasic from '@/assets/images/code/YABASIC.svg'
import mongoDB from '@/assets/images/code/mongoDB.svg'
import racket from '@/assets/images/code/racket.svg'

import APL from '@/assets/images/code/APL.svg'
import Cow from '@/assets/images/code/Cow.svg'
import Crystal from '@/assets/images/code/Crystal.svg'
import Deno from '@/assets/images/code/Deno.svg'
import Flask from '@/assets/images/code/Flask.svg'
import Jelly from '@/assets/images/code/Jelly.svg'
import Laravel from '@/assets/images/code/Laravel.svg'
import Moonscript from '@/assets/images/code/Moonscript.svg'
import React from '@/assets/images/code/React.svg'
import SpringBoot from '@/assets/images/code/SpringBoot.svg'
import Tasm from '@/assets/images/code/Tasm.svg'
import Typescript from '@/assets/images/code/Typescript.svg'
import Vue from '@/assets/images/code/Vue.svg'
import ExpressJs from '@/assets/images/code/express-original.svg'
import Julia from '@/assets/images/code/julia.svg'
import PostgreSQL from '@/assets/images/code/postgresql.svg'
import Raku from '@/assets/images/code/raku.svg'

import type { ISelectListItem } from '@/components/forms/ISelectListItem'
export interface ILanguage extends ISelectListItem {
  searchTerm: string
  link: string
  displayName: string
  language?: string
  versions?: string[]
  popular?: boolean
  other?: boolean
  isAdvanced?: boolean
  isSupportedInAssignments: boolean
  isPopular?: boolean
  isPopularNumber?: number
  isFeatured?: boolean
  isFeaturedOrder?: number
  category: string
  icon: string
  darkIcon: string | undefined
  advanceCompiler?: true
  advanceCompilerDatabase?: true
  languageType: LANGUAGE_TYPE
  categoryText?: string
}
/**
 * Get the language count from meta
 * @returns language count
 */
export const getLanguageCount = (): string => {
  const metaArray = Object.values(LANGUAGEMETA)
  const filtedArray = metaArray.filter(
    (item) =>
      item.langDisplayName !== undefined &&
      item.langDisplayName !== 'mongoDB' &&
      item.langDisplayName !== 'MySQL'
  )
  return filtedArray.length > 0 ? filtedArray.length.toString() : '88'
}

export enum LANGUAGE_CATEGORY {
  POPULAR = 'Popular',
  OOP = 'OOP',
  SCRIPTING = 'Scripting Language',
  FUNCTIONAL = 'Functional Programming',
  WEB_DEV = 'Web Development',
  PROCEDURAL = 'Procedural Programming',
  OTHERS = 'Others'
}

export enum LANGUAGE_TYPE {
  FRONTEND = 'frontend',
  BACKEND = 'backend',
  DATABASE = 'database',
  PROGRAMMING_LANG = 'programming-language'
}

export const noneLanguage: ILanguage = {
  displayName: 'None',
  language: 'none',
  searchTerm: '',
  link: '',
  isSupportedInAssignments: true,
  category: '',
  icon: Java,
  darkIcon: undefined,
  languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
}

export const languagesItems: ILanguage[] = [
  {
    searchTerm: 'java',
    link: '/online-java-compiler/',
    displayName: 'Java',
    language: 'java',
    popular: true,
    versions: ['JDK 1.8.0_66', 'JDK 9.0.1', 'JDK 10.0.1', 'JDK 11.0.4', 'JDK 17.0.1', 'JDK 21.0.0'],
    isSupportedInAssignments: true,
    isPopular: true,
    isPopularNumber: 1,
    isFeatured: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Java,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'java',
    link: '/online-java-compiler-ide/',
    isAdvanced: true,
    displayName: 'Java (Advanced)',
    language: 'java',
    popular: true,
    versions: ['JDK 1.8.0_66', 'JDK 9.0.1', 'JDK 10.0.1', 'JDK 11.0.4', 'JDK 17.0.1'],
    isSupportedInAssignments: false,
    isPopular: true,
    isPopularNumber: 2,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Java,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'html javascript js css react angular vue ',
    link: '/html-css-javascript-online-editor/',
    displayName: 'HTML/CSS/Javascript',
    language: 'html',
    other: true,
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: HTML_CSS_Javascript,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'html javascript css react angular',
    link: '/html-css-javascript-online-editor-ide/',
    isAdvanced: true,
    displayName: 'HTML/CSS/Javascript (Advanced)',
    language: 'html',
    other: true,
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: HTML_CSS_Javascript,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c',
    link: '/c-online-compiler/',
    displayName: 'C',
    language: 'c',
    popular: true,
    versions: [
      'GCC 5.3.0',
      'Zapcc 5.0.0',
      'GCC 7.2.0',
      'GCC 8.1.0',
      'GCC 9.1.0',
      'GCC 11.1.0',
      'GCC 13.2.1'
    ],
    isSupportedInAssignments: true,
    isPopular: true,
    isFeatured: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: C,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c',
    link: '/c-online-compiler-ide/',
    isAdvanced: true,
    displayName: 'C (Advanced)',
    language: 'c',
    popular: true,
    versions: ['GCC 13.2.1'],
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: C,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c++ cpp',
    link: '/online-compiler-c++/',
    displayName: 'C++',
    language: 'cpp',
    popular: true,
    versions: [
      'GCC 5.3.0',
      'Zapcc 5.0.0',
      'GCC 7.2.0',
      'GCC 8.1.0',
      'GCC 9.1.0',
      'GCC 11.1.0',
      'GCC 13.2.1'
    ],
    isSupportedInAssignments: true,
    isPopular: true,
    isPopularNumber: 6,
    isFeatured: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Cpp,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c++ cpp',
    link: '/online-compiler-c++-ide/',
    isAdvanced: true,
    displayName: 'C++ (Advanced)',
    language: 'cpp',
    popular: true,
    versions: ['GCC 13.2.1'],
    isSupportedInAssignments: false,
    isPopular: true,
    isPopularNumber: 7,
    isFeatured: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Cpp,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c++ 14 cpp14 c++14',
    link: '/online-compiler-c++14/',
    displayName: 'C++ 14',
    language: 'cpp14',
    other: true,
    versions: [
      'g++ 14 GCC 5.3.0',
      'g++ 14 GCC 7.2.0',
      'g++ 14 GCC 8.1.0',
      'g++ 14 GCC 9.1.0',
      'GCC 11.1.0',
      'GCC 13.2.1'
    ],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Cpp,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c++ 14 cpp14 c++14',
    link: '/online-compiler-c++14-ide/',
    isAdvanced: true,
    displayName: 'C++ 14 (Advanced)',
    language: 'cpp14',
    other: true,
    versions: ['GCC 13.2.1'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Cpp,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c++ 17 cpp17 c++17',
    link: '/online-compiler-c++17/',
    displayName: 'C++ 17',
    language: 'cpp17',
    popular: true,
    versions: ['g++ 17 GCC 9.1.0', 'g++ 17 GCC 11.1.0', 'g++ 17 GCC 13.2.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Cpp,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c++ 17 cpp17 c++17',
    link: '/online-compiler-c++17-ide/',
    isAdvanced: true,
    displayName: 'C++ 17 (Advanced)',
    language: 'cpp17',
    popular: true,
    versions: ['g++ 17 GCC 13.2.1'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Cpp,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c 99 c99',
    link: '/compile-c99-online/',
    displayName: 'C99',
    language: 'c99',
    other: true,
    versions: ['GCC 5.3.0', 'GCC 7.2.0', 'GCC 8.1.0', 'GCC 9.1.0', 'GCC 11.1.0', 'GCC 13.2.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: C,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c 99 c99',
    link: '/compile-c99-online-ide/',
    isAdvanced: true,
    displayName: 'C99 (Advanced)',
    language: 'c99',
    other: true,
    versions: ['GCC 13.2.1'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: C,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c sharp c# csharp',
    link: '/compile-c-sharp-online/',
    displayName: 'C#',
    language: 'csharp',
    popular: true,
    versions: [
      'mono 4.2.2',
      'mono 5.0.0',
      'mono 5.10.1',
      'mono 6.0.0',
      'mono-6.12.0',
      'dotnet: 7.0.13'
    ],
    isSupportedInAssignments: true,
    isPopular: true,
    isPopularNumber: 3,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Csharp,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'c sharp c# csharp',
    link: '/compile-c-sharp-online-ide/',
    isAdvanced: true,
    displayName: 'C# (Advanced)',
    language: 'csharp',
    popular: true,
    versions: ['dotnet-7.0.13'],
    isSupportedInAssignments: false,
    isPopular: true,
    isPopularNumber: 4,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Csharp,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'php',
    link: '/php-online-editor/',
    displayName: 'PHP',
    language: 'php',
    popular: true,
    versions: ['5.6.16', '7.1.11', '7.2.5', '7.3.10', '8.0.13', '8.2.12'],
    isSupportedInAssignments: true,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: PHP,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'php',
    link: '/php-online-editor-ide/',
    isAdvanced: true,
    displayName: 'PHP (Advanced)',
    language: 'php',
    popular: true,
    versions: ['8.2.12'],
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: PHP,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'perl',
    link: '/execute-perl-online/',
    displayName: 'Perl',
    language: 'perl',
    popular: true,
    versions: ['5.22.0', '5.26.1', '5.26.2', '5.30.0', '5.34.0', '5.38.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Perl,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'perl',
    link: '/execute-perl-online-ide/',
    isAdvanced: true,
    displayName: 'Perl (Advanced)',
    language: 'perl',
    popular: true,
    versions: ['5.38.0'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Perl,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'ruby',
    link: '/execute-ruby-online/',
    displayName: 'Ruby',
    language: 'ruby',
    other: true,
    versions: ['2.2.4', '2.4.2p198', '2.5.1p57', '2.6.5', '3.0.2', '3.0.6'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Ruby,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'ruby',
    link: '/execute-ruby-online-ide/',
    isAdvanced: true,
    displayName: 'Ruby (Advanced)',
    language: 'ruby',
    other: true,
    versions: ['3.0.6'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Ruby,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },

  {
    searchTerm: 'python 2 python2',
    link: '/python-programming-online/',
    displayName: 'Python2',
    language: 'python2',
    popular: true,
    versions: ['2.7.11', '2.7.15', '2.7.16', '2.7.18'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Python2,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'python 2 python2',
    link: '/python-programming-online-ide/',
    isAdvanced: true,
    displayName: 'Python2 (Advanced)',
    language: 'python2',
    popular: true,
    versions: ['2.7.18'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Python2,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'python 3 python3',
    link: '/python3-programming-online/',
    displayName: 'Python3',
    language: 'python3',
    popular: true,
    versions: ['3.5.1', ' 3.6.3', '3.6.5', '3.7.4', '3.9.9', '3.11.5'],
    isSupportedInAssignments: true,
    isPopular: true,
    isPopularNumber: 4,
    isFeatured: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Python2,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'python 3 python3',
    link: '/python3-programming-online-ide/',
    isAdvanced: true,
    displayName: 'Python3 (Advanced)',
    language: 'python3',
    popular: true,
    versions: ['3.11.5'],
    isSupportedInAssignments: false,
    isPopular: true,
    isPopularNumber: 5,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Python2,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'sql',
    link: '/execute-sql-online/',
    displayName: 'SQL',
    language: 'sql',
    popular: true,
    versions: [
      'SQLite 3.9.2',
      'SQLite 3.21.0',
      'SQLite 3.23.1',
      'SQLite 3.29.0',
      'SQLite 3.37.0',
      'SQLite 3.44.0'
    ],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: SQL,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'scala',
    link: '/compile-scala-online/',
    displayName: 'Scala',
    language: 'scala',
    popular: true,
    versions: ['2.12.0', '2.12.4', '2.12.5', '2.13.0', '2.13.6', '2.13.12'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Scala,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'scala',
    link: '/compile-scala-online-ide/',
    isAdvanced: true,
    displayName: 'Scala (Advanced)',
    language: 'scala',
    popular: true,
    versions: ['2.13.12'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Scala,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'vb.net',
    link: '/compile-vb-dot-net-online/',
    displayName: 'VB.Net',
    language: 'vbn',
    other: true,
    versions: ['mono 4.0.1', 'mono 4.6', 'mono 5.10.1', 'mono 6.0.0', 'mono 6.12.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: VB,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'vb.net',
    link: '/compile-vb-dot-net-online-ide/',
    isAdvanced: true,
    displayName: 'VB.Net (Advanced)',
    language: 'vbn',
    other: true,
    versions: ['dotnet: 7.0.13'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.OOP,
    icon: VB,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'pascal',
    link: '/execute-pascal-online/',
    displayName: 'Pascal',
    language: 'pascal',
    popular: true,
    versions: ['fpc 3.0.0', 'fpc-3.0.2', 'fpc-3.0.4', 'fpc-3.2.2'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: Pascal,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'haskell',
    link: '/execute-haskell-online/',
    displayName: 'Haskell',
    language: 'haskell',
    other: true,
    versions: ['ghc 7.10.3', 'ghc 8.2.1', 'ghc 8.2.2', 'ghc 8.6.5', 'ghc 9.0.1', 'ghc 9.0.2'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.FUNCTIONAL,
    icon: Haskell,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'go',
    link: '/execute-go-online/',
    displayName: 'Go',
    language: 'go',
    other: true,
    versions: ['1.5.2', '1.9.2', '1.10.2', '1.13.1', '1.17.3', '1.21.4'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: Go,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'swift',
    link: '/execute-swift-online/',
    displayName: 'Swift',
    language: 'swift',
    popular: true,
    versions: ['2.2', '3.1.1', '4.1', '5.1', '5.5', '5.9.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Swift,
    darkIcon: Swift_dark,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'swift',
    link: '/execute-swift-online-ide/',
    isAdvanced: true,
    displayName: 'Swift (Advanced)',
    language: 'swift',
    popular: true,
    versions: ['5.9.1'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Swift,
    darkIcon: Swift_dark,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'objective c',
    link: '/execute-objective-c-online/',
    displayName: 'Objective-C',
    language: 'objc',
    other: true,
    versions: ['GCC 5.3.0', 'GCC 7.2.0', 'GCC 8.1.0', 'GCC 9.1.0', 'GCC 11.1.0', 'GCC 13.2.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: ObjectiveC,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'groovy',
    link: '/execute-groovy-online/',
    displayName: 'Groovy',
    language: 'groovy',
    popular: true,
    versions: [
      '2.4.6 JVM: 1.7.0_99',
      '2.4.12 JVM: 9.0.1',
      '2.4.15 JVM: 10.0.1',
      '2.5.8 JVM: 11.0.4',
      '3.0.9 JVM: 17.0.1',
      '4.0.15 JVM: 21'
    ],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Groovy,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'groovy',
    link: '/execute-groovy-online-ide/',
    isAdvanced: true,
    displayName: 'Groovy (Advanced)',
    language: 'groovy',
    popular: true,
    versions: ['4.0.15 JVM: 21'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Groovy,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'fortran',
    link: '/execute-fortran-online/',
    displayName: 'Fortran',
    language: 'fortran',
    other: true,
    versions: ['GNU 5.3.0', 'GNU 7.2.0', 'GNU 8.1.0', 'GNU 9.1.0', 'GNU 11.1.0', 'GNU 13.2.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: Fortran,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },

  {
    searchTerm: 'brainfuck b******k',
    link: '/execute-b******k-online/',
    displayName: 'B******K',
    language: 'brainfuck',
    other: true,
    versions: ['bfc-0.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Brainf__k,
    darkIcon: Brainf__k_dark,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'hack',
    link: '/execute-hack-online/',
    displayName: 'Hack',
    language: 'hack',
    other: true,
    versions: ['HipHop VM 3.13.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Hack,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'tcl',
    link: '/execute-tcl-online/',
    displayName: 'TCL',
    language: 'tcl',
    other: true,
    versions: ['8.6', '8.6.7', '8.6.8', '8.6.9', '8.6.12', '8.6.13'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: TCL,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'lua',
    link: '/execute-lua-online/',
    displayName: 'Lua',
    language: 'lua',
    popular: true,
    versions: ['5.3.2', '5.3.4', '5.3.5', '5.4.3', '5.4.6'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: Lua,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'lua',
    link: '/execute-lua-online-ide/',
    isAdvanced: true,
    displayName: 'Lua (Advanced)',
    language: 'lua',
    popular: true,
    versions: ['5.4.6'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: Lua,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'rust',
    link: '/execute-rust-online/',
    displayName: 'Rust',
    language: 'rust',
    other: true,
    versions: ['1.10.0', '1.21.0', '1.25.0', '1.38.0', '1.56.1', '1.73.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Rust,
    darkIcon: Rust_dark,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'r',
    link: '/execute-r-online/',
    displayName: 'R',
    language: 'r',
    other: true,
    versions: ['3.3.1', '3.4.2', '3.5.0', '3.6.1', '4.1.2', '4.3.2'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: R,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'r',
    link: '/execute-r-online-ide/',
    isAdvanced: true,
    displayName: 'R (Advanced)',
    language: 'r',
    other: true,
    versions: ['4.3.2'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: R,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'ada',
    link: '/execute-ada-online/',
    displayName: 'Ada',
    language: 'ada',
    other: true,
    versions: [
      'GNATMAKE 6.1.1',
      'GNATMAKE 7.2.0',
      'GNATMAKE 8.1.0',
      'GNATMAKE 9.1.0',
      'GNATMAKE 11.1.0',
      'GNATMAKE 13.2.1'
    ],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Ada,
    darkIcon: ADA_dark,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'd',
    link: '/execute-d-online/',
    displayName: 'D',
    language: 'd',
    other: true,
    versions: [
      'DMD64 D Compiler v2.071.1',
      'DMD64 D Compiler v2.088',
      'DMD64 D Compiler v2.098',
      'DMD64 D Compiler v2.105.2'
    ],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: D,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'dart',
    link: '/execute-dart-online/',
    displayName: 'Dart',
    language: 'dart',
    other: true,
    versions: ['1.18.0', '1.24.2', '1.24.3', '2.5.1', '2.14.4', '3.0.7'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Dart,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'dart',
    link: '/execute-dart-online-ide/',
    isAdvanced: true,
    displayName: 'Dart (Advanced)',
    language: 'dart',
    other: true,
    versions: ['3.0.7'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Dart,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'yabasic',
    link: '/execute-yabasic-online/',
    displayName: 'YaBasic',
    language: 'yabasic',
    other: true,
    versions: ['2.769', '2.84.1', '2.90.4'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Yabasic,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'freebasic',
    link: '/execute-freebasic-online/',
    displayName: 'Free Basic',
    language: 'freebasic',
    other: true,
    versions: ['1.05.0', '1.07.1', '1.08.1', '1.10.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: FreeBasic,
    darkIcon: FreeBasic_dark,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'freebasic',
    link: '/execute-freebasic-online-ide/',
    isAdvanced: true,
    displayName: 'Free Basic (Advanced)',
    language: 'freebasic',
    other: true,
    versions: ['1.10.0'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: FreeBasic,
    darkIcon: FreeBasic_dark,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'clojure',
    link: '/execute-clojure-online/',
    displayName: 'Clojure',
    language: 'clojure',
    other: true,
    versions: ['1.8.0', '1.9.0', '1.10.1', '1.10.3', '1.11.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.FUNCTIONAL,
    icon: Clojure,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'verilog',
    link: '/execute-verilog-online/',
    displayName: 'Verilog',
    language: 'verilog',
    other: true,
    versions: ['10.1', '10.2', '10.3', '11', '12'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: Verilog,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'verilog',
    link: '/execute-verilog-online-ide/',
    isAdvanced: true,
    displayName: 'Verilog (Advanced)',
    language: 'verilog',
    other: true,
    versions: ['12'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: Verilog,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'node nodejs',
    link: '/execute-nodejs-online/',
    displayName: 'NodeJS',
    language: 'nodejs',
    popular: true,
    versions: ['6.3.1', '9.2.0', '10.1.0', '12.11.1', '17.1.0', '20.9.0', '21.2.0'],
    isSupportedInAssignments: true,
    isPopular: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: NodeJS,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'node nodejs',
    link: '/execute-nodejs-online-ide/',
    isAdvanced: true,
    displayName: 'NodeJS (Advanced)',
    language: 'nodejs',
    popular: true,
    versions: ['21.2.0'],
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: NodeJS,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'scheme',
    link: '/execute-scheme-online/',
    displayName: 'Scheme',
    language: 'scheme',
    other: true,
    versions: ['Gauche 0.9.4', 'Gauche 0.9.5', 'Gauche 0.9.8', 'Gauche 0.9.10', 'Gauche 0.9.13'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.FUNCTIONAL,
    icon: Scheme,
    darkIcon: Scheme_dark,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'forth',
    link: '/execute-forth-online/',
    displayName: 'Forth',
    language: 'forth',
    other: true,
    versions: ['gforth 0.7.3', '4th 3.64'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Forth,
    darkIcon: Forth_dark,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },

  {
    searchTerm: 'prolog',
    link: '/execute-prolog-online/',
    displayName: 'Prolog',
    language: 'prolog',
    other: true,
    versions: ['GNU Prolog 1.4.4', 'GNU Prolog 1.4.5', 'GNU Prolog 1.5.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Prolog,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'bash',
    link: '/test-bash-shell-script-online/',
    displayName: 'Bash',
    language: 'bash',
    other: true,
    versions: ['4.3.42', '4.4.12', '4.4.19', '5.0.011', '5.1.12', '5.2.15'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Bash,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'bash',
    link: '/test-bash-shell-script-online-ide/',
    isAdvanced: true,
    displayName: 'Bash (Advanced)',
    language: 'bash',
    other: true,
    versions: ['5.2.15'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Bash,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'cobol',
    link: '/execute-cobol-online/',
    displayName: 'COBOL',
    language: 'cobol',
    popular: true,
    versions: [
      'GNU COBOL 2.0.0',
      'GNU COBOL 2.2.0',
      'GNU COBOL 3.0',
      'GNU COBOL 3.1.2',
      'GNU COBOL 3.2.0'
    ],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: Cobol,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'cobol',
    link: '/execute-cobol-online-ide/',
    isAdvanced: true,
    displayName: 'COBOL (Advanced)',
    language: 'cobol',
    popular: true,
    versions: ['GNU COBOL 3.2.0'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: Cobol,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'octave matlab',
    link: '/execute-octave-matlab-online/',
    displayName: 'OCTAVE/Matlab',
    language: 'octave',
    popular: true,
    versions: ['GNU 4.0.0', 'GNU 4.2.1', 'GNU 4.4.0', 'GNU 5.1.0', 'GNU 6.4.0', 'GNU 8.4.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.FUNCTIONAL,
    icon: OCTAVE_Matlab,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'icon',
    link: '/compile-icon-online/',
    displayName: 'Icon',
    language: 'icon',
    other: true,
    versions: ['9.4.3', '9.5.1', '9.5.23'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: IconLogo,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'coffeescript',
    link: '/compile-coffeescript-online/',
    displayName: 'CoffeeScript',
    language: 'coffeescript',
    other: true,
    versions: ['1.11.1', '2.0.0', '2.3.0', '2.4.1', '2.6.1', '2.7.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: CoffeeScript,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },

  {
    searchTerm: 'assembler gcc assembly',
    link: '/compile-assembler-gcc-online/',
    displayName: 'Assembler (GCC)',
    language: 'gccasm',
    other: true,
    versions: ['GCC 6.2.1', 'GCC 8.1.0', 'GCC 9.1.0', 'GCC 11.1.0', 'GCC 13.2.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: AssemblerGCC,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'f# fsharp',
    link: '/compile-fsharp-online/',
    displayName: 'F#',
    language: 'fsharp',
    other: true,
    versions: ['4.1', '4.5.0', '10.2.3'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.FUNCTIONAL,
    icon: FSharp,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'assembler nasm assembly',
    link: '/compile-assembler-nasm-online/',
    displayName: 'Assembler (NASM)',
    language: 'nasm',
    popular: true,
    versions: ['2.11.08', '2.13.01', '2.13.03', '2.14.02', '2.15.05', '2.16.01'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: AssemblerNASM,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'assembler nasm assembly',
    link: '/compile-assembler-nasm-online-ide/',
    isAdvanced: true,
    displayName: 'Assembler (NASM) (Advanced)',
    language: 'nasm',
    popular: true,
    versions: ['2.16.01'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: AssemblerNASM,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'intercal',
    link: '/compile-intercal-online/',
    displayName: 'Intercal',
    language: 'intercal',
    other: true,
    versions: ['0.31'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Intercal,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },

  {
    searchTerm: 'nemerle',
    link: '/compile-nemerle-online/',
    displayName: 'Nemerle',
    language: 'nemerle',
    other: true,
    versions: ['1.2.0.507'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: Nemerle,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'ocaml',
    link: '/compile-ocaml-online/',
    displayName: 'Ocaml',
    language: 'ocaml',
    other: true,
    versions: ['4.03.0', '4.08.1', '4.12.0', '5.0.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.FUNCTIONAL,
    icon: Ocaml,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },

  {
    searchTerm: 'unlambda',
    link: '/execute-unlambda-online/',
    displayName: 'Unlambda',
    language: 'unlambda',
    other: true,
    versions: ['0.1.3', '0.1.4.2'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Unlambda,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'picolisp',
    link: '/execute-picolisp-online/',
    displayName: 'Picolisp',
    language: 'picolisp',
    other: true,
    versions: ['3.1.11.1', '17.11.14', '18.5.11', '18.9.5', '21.6.30', '23.9.29'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Picolisp,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'clisp',
    link: '/execute-clisp-online/',
    displayName: 'CLISP',
    language: 'clisp',
    other: true,
    versions: [
      'GNU CLISP 2.49 - GNU C 5.2.0 ',
      'GNU CLISP 2.49 - GNU C 6.2.1',
      'GNU CLISP 2.49.93 - GNU 8.1.0',
      'GNU CLISP 2.49.93 - GNU 9.1.0',
      'ecl 21.2.1',
      'sbcl 2.1.9',
      'ccl 1.12.1',
      'abcl 1.8.0',
      'ecl 23.9.9',
      'sbcl 2.3.10',
      'ccl 1.12.2',
      'abcl 1.9.2'
    ],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.FUNCTIONAL,
    icon: Lisp,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'elixir',
    link: '/execute-elixir-online/',
    displayName: 'Elixir',
    language: 'elixir',
    other: true,
    versions: ['1.3.4', '1.5.2', '1.6.4', '1.9.1', '1.12.2', '1.15.7'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.FUNCTIONAL,
    icon: Elixir,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },

  {
    searchTerm: 'spidermonkey javascript js',
    link: '/execute-spidermonkey-online/',
    displayName: 'SpiderMonkey',
    language: 'spidermonkey',
    other: true,
    versions: ['38', '45.0.2', '91'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: SpiderMonkey,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'rhinojs javascript',
    link: '/execute-rhino-online/',
    displayName: 'Rhino JS',
    language: 'rhino',
    other: true,
    versions: ['1.7.7.1', '1.7.7.2', '1.7.13', '1.7.14'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Rhino,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'bc',
    link: '/execute-bc-online/',
    displayName: 'BC',
    language: 'bc',
    other: true,
    versions: ['1.06.95', '1.07.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: BC,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'nim',
    link: '/execute-nim-online/',
    displayName: 'Nim',
    language: 'nim',
    other: true,
    versions: ['0.15.0', '0.17.2', '0.18.0', '1.4.8', '2.0.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Nim,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },

  {
    searchTerm: 'factor',
    link: '/execute-factor-online/',
    displayName: 'Factor',
    language: 'factor',
    other: true,
    versions: ['8.25', '8.28', '8.29', '8.31', '9.4'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Factor,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'falcon',
    link: '/execute-falcon-online/',
    displayName: 'Falcon',
    language: 'falcon',
    other: true,
    versions: ['0.9.6.8 (Chimera)'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Falcon,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'fantom',
    link: '/execute-fantom-online/',
    displayName: 'Fantom',
    language: 'fantom',
    other: true,
    versions: ['1.0.69'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Fantom,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'pike',
    link: '/execute-pike-online/',
    displayName: 'Pike',
    language: 'pike',
    other: true,
    versions: ['v8.0', 'v8.0.702'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Pike,
    darkIcon: Pike_dark,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'smalltalk',
    link: '/execute-smalltalk-online/',
    displayName: 'SmallTalk',
    language: 'smalltalk',
    other: true,
    versions: ['GNU SmallTalk 3.2.92'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: SmallTalk,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'kotlin',
    link: '/compile-kotlin-online/',
    displayName: 'Kotlin',
    language: 'kotlin',
    other: true,
    versions: [
      '1.1.51 (JRE 9.0.1+11)',
      '1.2.40 (JRE 10.0.1)',
      '1.3.50 (JRE 11.0.4)',
      '1.6.0 (JRE 17.0.1+12)',
      '1.9.10 (JRE 17.0.1+12)'
    ],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Kotlin,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'kotlin',
    link: '/compile-kotlin-online-ide/',
    isAdvanced: true,
    displayName: 'Kotlin (Advanced)',
    language: 'kotlin',
    other: true,
    versions: ['1.9.10 (JRE 17.0.1+12)'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Kotlin,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'lolcode',
    link: '/execute-lolcode-online/',
    displayName: 'LOLCODE',
    language: 'lolcode',
    other: true,
    versions: ['0.10.5'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: LOLCODE,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'racket',
    link: '/execute-racket-online/',
    displayName: 'Racket',
    language: 'racket',
    other: true,
    versions: ['6.11', '6.12', '7.4', '8.3'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.FUNCTIONAL,
    icon: racket,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'oz-mozart',
    link: '/compile-oz-mozart-online/',
    displayName: 'OZ Mozart',
    language: 'mozart',
    other: true,
    versions: ['2.0.0 (OZ 3)'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: Mozart,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'whitespace',
    link: '/execute-whitespace-online/',
    displayName: 'Whitespace',
    language: 'whitespace',
    other: true,
    versions: ['0.3'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: Whitespace,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'erlang',
    link: '/execute-erlang-online/',
    displayName: 'Erlang',
    language: 'erlang',
    other: true,
    versions: ['22.1', '24', '26.1.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.FUNCTIONAL,
    icon: Erlang,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'j language jlanguage',
    link: '/execute-jlanguage-online/',
    displayName: 'J Language',
    language: 'jlang',
    other: true,
    versions: ['9.01.10'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: JLang,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },

  {
    searchTerm: 'jbang java',
    link: '/try-jbang/',
    displayName: 'JBang',
    language: 'jbang',
    other: true,
    versions: ['0.83.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: JBang,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'fasm assembly assembler',
    link: '/execute-fasm-online/',
    displayName: 'Assembler (FASM)',
    language: 'fasm',
    other: true,
    versions: ['1.73.27', '1.73.31'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: AssemblerFASM,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'awk',
    link: '/execute-awk-online/',
    displayName: 'AWK',
    language: 'awk',
    other: true,
    versions: ['GNU Awk 5.1.1, API: 3.1', 'API: 5.3'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: AWK,
    darkIcon: AWK_dark,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'algol68',
    link: '/execute-algol68-online/',
    displayName: 'Algol 68',
    language: 'algol',
    other: true,
    versions: ['Genie 2.8.5', 'Genie 3.4.2'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: Algol68,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'befunge cfunge',
    link: '/execute-befunge-online/',
    displayName: 'Befunge',
    language: 'befunge',
    other: true,
    versions: ['cfunge 0.9.0', 'cfunge 1.1.001'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Befunge,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'haxe',
    link: '/execute-haxe-online/',
    displayName: 'Haxe',
    language: 'haxe',
    other: true,
    versions: ['4.2.4', '4.3.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Haxe,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'moonscript',
    link: '/execute-moonscript-online/',
    displayName: 'Moonscript',
    language: 'moonscript',
    other: true,
    versions: ['0.5.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Moonscript,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'assembler tasm assembly turbo',
    link: '/compile-assembler-tasm-online/',
    displayName: 'Assembler (Turbo)',
    language: 'tasm',
    other: true,
    versions: ['1.59.3120'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.PROCEDURAL,
    icon: Tasm,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'deno',
    link: '/execute-deno-online/',
    displayName: 'Deno',
    language: 'deno',
    other: true,
    versions: ['1.38.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Deno,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'jelly',
    link: '/execute-jelly-online/',
    displayName: 'Jelly',
    language: 'jelly',
    other: true,
    versions: ['1.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Jelly,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'cow',
    link: '/execute-cow-online/',
    displayName: 'Cow',
    language: 'cow',
    other: true,
    versions: ['1.0'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Cow,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'apl',
    link: '/compile-apl-online/',
    displayName: 'Apl',
    language: 'apl',
    other: true,
    versions: ['1.8'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.FUNCTIONAL,
    icon: APL,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'crystal',
    link: '/online-compiler-crystal/',
    displayName: 'Crystal',
    language: 'crystal',
    other: true,
    versions: ['1.10.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Crystal,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'typescript',
    link: '/online-compiler-typescript/',
    displayName: 'Typescript',
    language: 'typescript',
    popular: true,
    versions: ['5.2.2'],
    isSupportedInAssignments: true,
    isPopular: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Typescript,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'typescript',
    link: '/online-compiler-typescript-ide/',
    isAdvanced: true,
    displayName: 'Typescript (Advanced)',
    language: 'typescript',
    popular: true,
    versions: ['5.2.2'],
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Typescript,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'raku',
    link: '/execute-raku-online/',
    displayName: 'Raku',
    language: 'raku',
    other: true,
    versions: ['2023.1'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Raku,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'julia',
    link: '/online-compiler-julia/',
    displayName: 'Julia',
    language: 'julia',
    other: true,
    versions: ['1.9.3'],
    isSupportedInAssignments: true,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Julia,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },
  {
    searchTerm: 'blockly',
    link: '/blockly-online/',
    displayName: 'Blockly',
    language: 'blockly',
    other: true,
    versions: ['Dart', 'Lua', 'PHP', 'Python'],
    isSupportedInAssignments: false,
    category: LANGUAGE_CATEGORY.SCRIPTING,
    icon: Blockly,
    darkIcon: undefined,
    languageType: LANGUAGE_TYPE.PROGRAMMING_LANG
  },

  {
    searchTerm: 'react',
    link: '/reactjs/',
    displayName: 'React',
    language: 'reactjs',
    popular: true,
    isSupportedInAssignments: false,
    isPopular: true,
    isPopularNumber: 2,
    category: LANGUAGE_CATEGORY.OOP,
    icon: React,
    darkIcon: undefined,
    advanceCompiler: true,
    versions: ['18.3.1'],
    languageType: LANGUAGE_TYPE.FRONTEND
  },
  {
    searchTerm: 'react',
    link: '/reactjs/',
    displayName: 'React',
    language: 'reactjs',
    popular: true,
    isSupportedInAssignments: false,
    isPopular: true,
    isPopularNumber: 2,
    category: LANGUAGE_CATEGORY.OOP,
    icon: React,
    darkIcon: undefined,
    isAdvanced: true,
    advanceCompiler: true,
    versions: ['18.3.1'],
    languageType: LANGUAGE_TYPE.FRONTEND
  },

  {
    searchTerm: 'vue',
    link: '/vuejs/',
    displayName: 'Vue',
    language: 'vuejs',
    isSupportedInAssignments: false,
    isPopular: true,
    isPopularNumber: 2,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Vue,
    darkIcon: undefined,
    advanceCompiler: true,
    versions: ['3.4.15'],
    languageType: LANGUAGE_TYPE.FRONTEND
  },

  {
    searchTerm: 'vue',
    link: '/vuejs/',
    displayName: 'Vue',
    language: 'vuejs',
    isSupportedInAssignments: false,
    isPopular: true,
    isPopularNumber: 2,
    category: LANGUAGE_CATEGORY.OOP,
    icon: Vue,
    darkIcon: undefined,
    isAdvanced: true,
    advanceCompiler: true,
    versions: ['3.4.15'],
    languageType: LANGUAGE_TYPE.FRONTEND
  },

  {
    searchTerm: 'expressjs',
    link: '/expressjs/',
    displayName: 'Express',
    language: 'expressjs',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: ExpressJs,
    darkIcon: undefined,
    advanceCompiler: true,
    versions: ['4.16.1'],
    languageType: LANGUAGE_TYPE.BACKEND
  },

  {
    searchTerm: 'expressjs',
    link: '/expressjs/',
    displayName: 'Express',
    language: 'expressjs',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: ExpressJs,
    darkIcon: undefined,
    isAdvanced: true,
    advanceCompiler: true,
    versions: ['4.16.1'],
    languageType: LANGUAGE_TYPE.BACKEND
  },

  {
    searchTerm: 'springboot',
    link: '/spring/',
    displayName: 'Spring Boot',
    language: 'spring',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: SpringBoot,
    darkIcon: undefined,
    advanceCompiler: true,
    versions: ['3.2.4'],
    languageType: LANGUAGE_TYPE.BACKEND
  },

  {
    searchTerm: 'springboot',
    link: '/spring/',
    displayName: 'Spring Boot',
    language: 'spring',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: SpringBoot,
    darkIcon: undefined,
    isAdvanced: true,
    advanceCompiler: true,
    versions: ['3.2.4'],
    languageType: LANGUAGE_TYPE.BACKEND
  },

  {
    searchTerm: 'flask',
    link: '/flask/',
    displayName: 'Flask',
    language: 'flask',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: Flask,
    darkIcon: undefined,
    advanceCompiler: true,
    versions: ['3.0.3'],
    languageType: LANGUAGE_TYPE.BACKEND
  },

  {
    searchTerm: 'flask',
    link: '/flask/',
    displayName: 'Flask',
    language: 'flask',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: Flask,
    darkIcon: undefined,
    isAdvanced: true,
    advanceCompiler: true,
    versions: ['3.0.3'],
    languageType: LANGUAGE_TYPE.BACKEND
  },
  {
    searchTerm: 'laravel',
    link: '/laravel/',
    displayName: 'Laravel',
    language: 'laravel',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: Laravel,
    darkIcon: undefined,
    advanceCompiler: true,
    versions: ['11.9'],
    languageType: LANGUAGE_TYPE.BACKEND
  },

  {
    searchTerm: 'laravel',
    link: '/laravel/',
    displayName: 'Laravel',
    language: 'laravel',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.WEB_DEV,
    icon: Laravel,
    darkIcon: undefined,
    isAdvanced: true,
    advanceCompiler: true,
    versions: ['11.9'],
    languageType: LANGUAGE_TYPE.BACKEND
  },
  {
    searchTerm: 'postgre psql',
    link: '/psql/',
    displayName: 'PostgreSQL',
    language: 'psql',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: PostgreSQL,
    darkIcon: undefined,
    advanceCompilerDatabase: true,
    languageType: LANGUAGE_TYPE.DATABASE
  },

  {
    searchTerm: 'postgre psql',
    link: '/psql/',
    displayName: 'PostgreSQL',
    language: 'psql',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: PostgreSQL,
    darkIcon: undefined,
    isAdvanced: true,
    advanceCompilerDatabase: true,
    languageType: LANGUAGE_TYPE.DATABASE
  },
  {
    searchTerm: 'mysql sql',
    link: '/mysql/',
    displayName: 'MySQL',
    language: 'mysql',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: MySQL,
    darkIcon: undefined,
    advanceCompilerDatabase: true,
    languageType: LANGUAGE_TYPE.DATABASE
  },

  {
    searchTerm: 'mysql sql',
    link: '/mysql/',
    displayName: 'MySQL',
    language: 'mysql',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: MySQL,
    darkIcon: undefined,
    isAdvanced: true,
    advanceCompilerDatabase: true,
    languageType: LANGUAGE_TYPE.DATABASE
  },
  {
    searchTerm: 'mongo mongodb',
    link: '/mongodb/',
    displayName: 'MongoDB',
    language: 'mongo',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: mongoDB,
    darkIcon: undefined,
    advanceCompilerDatabase: true,
    languageType: LANGUAGE_TYPE.DATABASE
  },

  {
    searchTerm: 'mongo mongodb',
    link: '/mongodb/',
    displayName: 'MongoDB',
    language: 'mongo',
    isSupportedInAssignments: false,
    isPopular: true,
    category: LANGUAGE_CATEGORY.OTHERS,
    icon: mongoDB,
    darkIcon: undefined,
    isAdvanced: true,
    advanceCompilerDatabase: true,
    languageType: LANGUAGE_TYPE.DATABASE
  }
]
