import type { IDocCategory } from '@/components/content-app/interface/docs/IDocCategory'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useSSGStore = defineStore('ssg', () => {
  const isSSG = ref<boolean>(false)
  const storeDocumentData = ref<IDocCategory[]>([])

  /**
   * Sets the visibility state of the mobile IDE Navbar navigation.
   * @param val set mobileIdeNav visible state
   */
  const setIsSSG = (val: boolean) => {
    isSSG.value = val
  }
  /**
   * Sets the visibility state of the mobile IDE Navbar navigation.
   * @param val set mobileIdeNav visible state
   */
  const setStoreDocumentData = (val: IDocCategory[]) => {
    storeDocumentData.value = val
  }

  return {
    isSSG,
    setIsSSG,
    setStoreDocumentData,
    storeDocumentData
  }
})
