<script setup lang="ts">
import { marked } from 'marked'

import jdroidIcon from '@/assets/images/ide/ai/jdroid-icon.svg'
import jdroidFrozen from '@/assets/images/ide/ai/jdroid-frozen.svg'
import dotLoader from '@/assets/images/svg/3-dot-loader.svg'

import { AUTHMODELHS, IDEVIEWMODELS } from '@/utils/models'
import { IDECONSTANT } from '@/utils/ide'
import { IDEVIEWTABS_MOBILE } from '@/utils/tabs'
import { PLAN_ENUM } from '@/components/organisation/interface/IInstituteSubscriptionInfo'
import {
  AICONSTANT,
  AITypeList,
  type IAIType,
  ChatStatus,
  type IChat,
  AIType,
  AISampleQuestions
} from '@/utils/jDroid'

import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick, type PropType } from 'vue'
import { useField, useForm } from 'vee-validate'
import { string } from 'yup'
import { cloneDeep, isEmpty } from 'lodash-es'
import { UNLIMITED_LIMIT } from '@/utils/sharedData/plans'
import editorService from '@/services/ide/editor.service'
import jdroidService from '@/services/ide/jdroid.service'
import projectsService from '@/services/ide/projects.service'
import { stopJdroidChat } from '@/services/jdroid.stream.service'
import { useAuthStore } from '@/stores/auth.store'
import { useOrganisationStore } from '@/stores/organisation.store'
import { useIdeStore } from '@/stores/ide.store'
import { useJdroidStore } from '@/stores/jdroid.store'

import ProfilePictureComp from '@/components/header/auth/profile/ProfilePictureComp.vue'
import Button from '@/components/atoms/button/index.vue'
import Credit from '@/components/ide/ide/ai/jdroid/CreditComp.vue'

const props = defineProps({
  isChatJdroid: {
    type: Boolean,
    required: false,
    default: false
  },
  chatId: {
    type: String as PropType<string | null>,
    required: false
  }
})

const emits = defineEmits(['startChat'])

const authStore = useAuthStore()
const organisationStore = useOrganisationStore()
const ideStore = useIdeStore()
const jdroidStore = useJdroidStore()

const { errors, handleSubmit, resetForm } = useForm()
const { value: prompt } = useField<string>('prompt', string())

const isLoggedIn = computed(() => {
  return authStore.isInitiated && authStore.isUserloggedIn
})
const accountType = computed(() => {
  return (organisationStore.instituteSubscriptionInfo?.plan as string) || null
})
const isFreePlan = computed(() => {
  return accountType.value === PLAN_ENUM.FREE
})
const isOwner = computed(() => {
  return useOrganisationStore().isOwner
})
const noCreditMessage = computed(() => {
  if (hasMoreCredits.value) return 'More credits added, refresh your query'
  return !isLoggedIn.value
    ? 'Login to get more credits'
    : !(isOwner.value && !isFreePlan.value)
    ? 'Get pro plan for unlimited credits'
    : "You've exhausted your AI credits today! Come back tomorrow."
})
const firstName = computed(() => authStore.firstName)

const quota = computed(() => jdroidStore.quota)
const used = computed(() => jdroidStore.used)
const remainingCredits = computed(() => {
  return quota.value - used.value
})

const hasMoreCredits = computed(() => {
  return remainingCredits.value > 0 || quota.value === UNLIMITED_LIMIT
})

const chatRef = ref<HTMLElement | null>(null)

const chatList = computed(() => {
  return jdroidStore.chatList
})
const isLastChatLoading = computed(() => {
  return jdroidStore.isLastChatLoading
})
const isLastChatSuccess = computed(() => {
  return jdroidStore.isLastChatSuccess
})
const canSubmit = computed(() => {
  return Object.keys(errors.value).length === 0 && !isLastChatLoading.value
})
const showPersonalProfilePic = computed(() => {
  return organisationStore.activeAccountId == organisationStore.personalAccountId
})
const currentAccountName = computed(() => {
  if (organisationStore.currentAssociation) {
    return organisationStore.currentAssociation?.name
  }
  return authStore.firstName
})
const isMobileView = computed(() => {
  return ideStore.currentMobileTab != null
})

interface ISampleQuestion {
  name: string
  disabled: boolean
}

const sampleQuestions = computed(() => {
  return AISampleQuestions.map(
    (question) =>
      ({
        name: question,
        disabled: false
      } as ISampleQuestion)
  )
})

/**
 * Handles the AI type
 * @param type IAIType
 */
const handleAi = (type: IAIType) => {
  const chat: IChat = {
    type: type.type,
    autoReplaceEditor: false,
    ideContent: null,
    outputContent: null,
    header: type.header,
    status: ChatStatus.LOADING,
    response: null
  }
  jdroidService.addChat(chat)
}
const onSubmit = handleSubmit(async (values) => {
  const chat: IChat = {
    type: props.isChatJdroid ? AIType.CHATJDROID : AIType.CHAT,
    autoReplaceEditor: false,
    ideContent: null,
    outputContent: null,
    prompt: values.prompt,
    header: 'Genarate Code',
    status: ChatStatus.LOADING,
    response: null
  }

  if (!isEmpty(prompt.value.trim())) {
    if (props.isChatJdroid) {
      emits('startChat', chat)
      sampleQuestions.value.forEach((element) => {
        element.disabled = false
      })
    } else {
      jdroidService.addChat(chat)
    }
    resetForm()
  }
})
/**
 * @param event
 * checks the enter and shift key pressed together, if not calls submit
 */
const onEnterChatBox = (event: any) => {
  if (!event.shiftKey) onSubmit()
}

/**
 * get marked response
 * @param chat IChat
 * @returns marked string
 */
const getMarkedResponse = (chat: IChat) => {
  chat.eventInjected = false
  chat.elementIDList = [] as string[]
  let script = marked(chat.response || '') as string

  // Regular expression to find pre code blocks
  const preTagRegex = /<pre(.*?)>(.*?)<\/pre>/gs // Modified Regex
  const preTagMatches = script.match(preTagRegex)

  if (preTagMatches) {
    preTagMatches.forEach((preMatch) => {
      const pre = preMatch.replace(/<pre(.*?)>|<\/pre>/g, '') // Extract pre
      // on that pre tag find what of code tag
      const codeTagRegex = /<code(.*?)>(.*?)<\/code>/gs
      const codeTagMatches = pre.match(codeTagRegex)
      if (codeTagMatches) {
        codeTagMatches.forEach((codeMatch) => {
          const code = codeMatch.replace(/<code(.*?)>|<\/code>/g, '') // Extract code

          // Generate a unique id from timestamp and random number
          const id = `code-${Date.now()}-${Math.floor(Math.random() * 1000)}`
          if (chat.elementIDList) chat.elementIDList.push(id)
          // Create the replacement div with your custom class
          const replacementDiv = `
          <div class="w-full ${
            !props.isChatJdroid && 'background-primary p-2 my-2 rounded-lg'
          }" id='${id}'>
               <div
                  class="flex h-8 w-full items-center justify-end gap-1.5 px-1">
                  <button id='copyCode' class="flex h-7 w-7 items-center
                   justify-center">
                   <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
  <path d="M18.0843 9.302V7.07327C18.0843 6.48218 17.8495 5.91529 17.4315 5.49732C17.0135 5.07936 16.4466 4.84454 15.8556 4.84454H6.94064C6.34955 4.84454 5.78266 5.07936 5.36469 5.49732C4.94673 5.91529 4.71191 6.48218 4.71191 7.07327V15.9882C4.71191 16.5793 4.94673 17.1462 5.36469 17.5641C5.78266 17.9821 6.34955 18.2169 6.94064 18.2169H9.16937M9.16937 11.5307C9.16937 10.9396 9.40418 10.3727 9.82215 9.95478C10.2401 9.53681 10.807 9.302 11.3981 9.302H20.313C20.9041 9.302 21.471 9.53681 21.889 9.95478C22.3069 10.3727 22.5417 10.9396 22.5417 11.5307V20.4456C22.5417 21.0367 22.3069 21.6036 21.889 22.0216C21.471 22.4396 20.9041 22.6744 20.313 22.6744H11.3981C10.807 22.6744 10.2401 22.4396 9.82215 22.0216C9.40418 21.6036 9.16937 21.0367 9.16937 20.4456V11.5307Z" stroke="#9EB1D5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                  </button>
                  <button id='acceptCode' class="transition-colors border-2  border-new-secondary-400 text-[#889CBF] hover:bg-new-secondary-400 hover:border-new-secondary-400 hover:text-white px-5 py-0.5 rounded text-sm leading-tight font-base"  style="${
                    !props.isChatJdroid ? 'display:flex' : 'display: none;'
                  }">
                   Copy to IDE
                  </button>
                </div>
           <div class="w-full px-2 overflow-auto ${
             props.isChatJdroid && 'background-secondary'
           } rounded-md py-2 my-1">
              <pre class="w-32 " ><code class="font-monaco text-sm" id='code'>${code}</code></pre>
            </div>
          </div>
          `
          // Replace the entire code block
          script = script.replace(preMatch, replacementDiv)
        })
      }
    })
  }
  return script
}
/**
 * copy the current code text to clipboard
 * @param script string
 * @returns true if success else false
 */
const copyToClipboard = (script: string) => {
  return window.navigator.clipboard.writeText(script).then(
    () => {
      return true
    },
    () => {
      return false
    }
  )
}

/**
 * Ask sample questions
 * @param question sample question
 */
const askSampleQuestions = (question: ISampleQuestion) => {
  question.disabled = true
  prompt.value = question.name
  onSubmit()
}

/**
 * replace code in ide
 * @param script string
 */
function replaceCodeInIde(script: string) {
  editorService.getEditor(IDECONSTANT.CODE_EDITOR).setValue(script, 1)
  if (isMobileView.value) {
    ideStore.setMobileTab(IDEVIEWTABS_MOBILE.IDE)
  }
  ideStore.setCodeUpdated(true)
  editorService.onCodeEditorUpdate()
}
/**
 * can resend
 * @param chat IChat
 * @returns true if can resend else false
 */
const canResend = (chat: IChat) => {
  return (
    chat.status === ChatStatus.ERROR ||
    chat.status === ChatStatus.CANCLED ||
    (chat.status === ChatStatus.NOCREDIT && hasMoreCredits.value) ||
    chat.status === ChatStatus.NOROBOTCHECK
  )
}

/**
 * resend chat
 * @param chat IChat
 */
const resendChat = (chat: IChat) => {
  if (canResend(chat)) {
    const newChat: IChat = cloneDeep(chat)
    newChat.status = ChatStatus.LOADING
    jdroidStore.removeChat(chat)
    if (props.isChatJdroid) {
      emits('startChat', newChat)
      sampleQuestions.value.forEach((element) => {
        element.disabled = false
      })
    } else {
      jdroidService.addChat(newChat)
    }
  }
}
/**
 * scroll to last chat
 */
const scrollToLastChat = async () => {
  await new Promise((resolve) => setTimeout(resolve, 100))
  nextTick(() => {
    if (chatRef.value) {
      // Scroll to the last chat smoothly
      chatRef.value.scrollTo({
        top: chatRef.value.scrollHeight,
        behavior: 'smooth'
      })
    }
  })
}
/**
 * append code events
 */
const appendCodeEvents = () => {
  chatList.value.forEach((chat) => {
    if (chat.eventInjected == false && isPositiveStatus(chat.status)) {
      chat.elementIDList?.forEach((id: string) => {
        const chatElement = document.getElementById(id)
        if (chatElement) {
          const code = chatElement.querySelector('#code')
          if (code) {
            // find the button has id copyCode
            const copyCodeBtn = chatElement.querySelector('#copyCode')
            if (copyCodeBtn) {
              copyCodeBtn.addEventListener('click', () => {
                copyToClipboard(code?.textContent as string)
              })
            }
            const acceptCodeBtn = chatElement.querySelector('#acceptCode')
            if (acceptCodeBtn) {
              acceptCodeBtn.addEventListener('click', () => {
                replaceCodeInIde(code?.textContent as string)
              })
            }
          }
        }
      })
    }
    chat.eventInjected = true
  })
}
/**
 * eject code events
 */
const ejectCodeEvents = () => {
  chatList.value.forEach((chat) => {
    if (chat.eventInjected == true && isPositiveStatus(chat.status)) {
      chat.elementIDList?.forEach((id: string) => {
        const chatElement = document.getElementById(id)
        if (chatElement) {
          const copyCodeBtn = chatElement.querySelector('#copyCode')
          if (copyCodeBtn) {
            copyCodeBtn.removeEventListener('click', () => {
              copyToClipboard(chatElement.querySelector('#code')?.textContent as string)
            })
          }
          const acceptCodeBtn = chatElement.querySelector('#acceptCode')
          if (acceptCodeBtn) {
            acceptCodeBtn.removeEventListener('click', () => {
              replaceCodeInIde(chatElement.querySelector('#code')?.textContent as string)
            })
          }
        }
      })
    }
    chat.eventInjected = false
  })
}
/**
 * @param status current status of chat
 * @returns boolean
 */
const isPositiveStatus = (status: ChatStatus) => {
  return status === ChatStatus.SUCCESS || status === ChatStatus.STREAMING
}

/**
 * @description - To stop the jdroid chat and cancel the API call
 */
const stopJdroidChatQuery = () => {
  props.isChatJdroid ? stopJdroidChat() : jdroidService.stopDebugging()
}

onMounted(() => {
  appendCodeEvents()
  watch(isMobileView, async () => {
    await new Promise((resolve) => setTimeout(resolve, 100))
    editorService.resizeCodeEditor()
  })
  scrollToLastChat()
  watch(isLastChatLoading, () => {
    scrollToLastChat()
  })
  watch(isLastChatSuccess, async (val) => {
    if (val) {
      await new Promise((resolve) => setTimeout(resolve, 100))
      appendCodeEvents()
      projectsService.autoSave()
    }
  })
})
onBeforeUnmount(async () => {
  await ejectCodeEvents()
})
</script>

<template>
  <div
    :id="AICONSTANT.CHAT"
    class="flex h-full w-full flex-col overflow-hidden"
    :class="isChatJdroid ? 'relative' : 'surface-secondary-base relative  sm:h-full '"
  >
    <div
      class="flex h-full w-full flex-col overflow-auto"
      :class="[isChatJdroid ? 'h-[calc(89dvh-180px)] pb-1 md:h-[calc(95dvh-170px)]' : ' pl-3 pt-3']"
    >
      <div
        v-if="isChatJdroid && chatList.length == 0"
        class="fade-in mt-[13%] flex w-full flex-col justify-center"
      >
        <div v-if="!chatId" class="w-full">
          <div class="gradient-text flex w-fit flex-col gap-3 text-5xl font-semibold">
            <p>
              Hello<span v-show="firstName" class="ml-2 inline-block">{{ firstName }}</span
              >,
            </p>
            <p>What should we build today?</p>
          </div>

          <div class="mt-4 flex flex-col sm:flex-row sm:gap-4">
            <button
              v-for="(q, index) in sampleQuestions"
              :key="index"
              class="btn border border-[#9EB1D5] hover:bg-[#D0DCF0]/50 disabled:cursor-not-allowed disabled:bg-[#D0DCF0]"
              :disabled="q.disabled"
              @click="askSampleQuestions(q)"
            >
              {{ q.name }}
            </button>
          </div>
        </div>
        <div v-else class="flex items-center justify-center text-neutral-300">No questions</div>
      </div>
      <div ref="chatRef" class="flex h-full w-full flex-col gap-3 overflow-y-auto">
        <div
          v-for="(chat, index) of chatList"
          :key="index"
          class="border-primary flex flex-col items-start justify-start gap-4"
          :class="{ 'gap-2': isChatJdroid }"
        >
          <div class="flex w-full items-start justify-start gap-4 px-2">
            <ProfilePictureComp
              :showPersonalProfilePic="showPersonalProfilePic"
              :isUserProfileImage="true"
              :currentName="currentAccountName"
            />

            <div class="flex flex-col items-start">
              <span
                :class="
                  isChatJdroid
                    ? 'mt-1.5 text-base font-semibold'
                    : 'text-high text-[13px] font-semibold'
                "
                >You</span
              >
              <span
                class="flex w-full items-start justify-start gap-4"
                :class="{ 'text-mid text-[13px] font-light': !isChatJdroid }"
                >{{ chat.prompt || chat.header }}</span
              >
            </div>
          </div>
          <div
            class="flex w-full flex-col gap-x-1 px-2"
            :class="{ 'rounded-xl bg-[#EBEFF6]/25 ': isChatJdroid }"
          >
            <div class="flex w-full justify-between">
              <div
                class="flex w-full items-center justify-start"
                :class="isChatJdroid ? 'gap-[13px]' : 'gap-4'"
              >
                <img
                  :src="
                    chat.status === ChatStatus.NOCREDIT || chat.status === ChatStatus.NOROBOTCHECK
                      ? jdroidFrozen
                      : jdroidIcon
                  "
                  :class="isChatJdroid ? 'h-9 w-9' : 'h-8 w-8'"
                  alt="JDroid"
                />
                <span
                  :class="[
                    {
                      'text-text-quaternary':
                        chat.status === ChatStatus.NOCREDIT ||
                        chat.status === ChatStatus.NOROBOTCHECK
                    },
                    isChatJdroid
                      ? 'text-base font-semibold'
                      : 'text-[13px] font-semibold leading-tight'
                  ]"
                  >JDroid AI</span
                >
                <button
                  v-if="canResend(chat)"
                  class="flex h-6 w-6 items-center justify-end"
                  @click="resendChat(chat)"
                >
                  <FontAwesomeIcon icon="fa-solid fa-rotate " class="h-4 w-4" />
                </button>
              </div>
              <div
                v-if="chat.status === ChatStatus.STREAMING"
                class="flex w-max cursor-pointer items-center justify-center whitespace-nowrap px-2 text-xs font-medium text-gray-500"
                @click="stopJdroidChatQuery"
              >
                <FontAwesomeIcon icon="fa-square" class="h-5 w-5 pr-1" /> Stop Query
              </div>
            </div>
            <div class="px-[50px]">
              <div
                v-if="chat.status === ChatStatus.LOADING"
                class="flex w-full items-center justify-start"
              >
                <span class="text-xs font-normal leading-tight text-red-500"
                  >Building your query</span
                >
                <img :src="dotLoader" class="h-[40px] w-[40px]" />
              </div>
              <div
                v-if="chat.status === ChatStatus.ERROR"
                class="flex w-full items-center justify-start"
              >
                <span class="text-xs font-normal leading-tight text-red-500"
                  >Sorry, something went wrong. Please try again.</span
                >
              </div>
              <div
                v-if="chat.status === ChatStatus.CANCLED"
                class="flex w-full items-center justify-start"
              >
                <span class="text-xs font-normal leading-tight text-red-500"
                  >Prompt interrupted.</span
                >
              </div>
              <div
                v-if="chat.status === ChatStatus.NOROBOTCHECK"
                class="flex w-full flex-col items-end justify-center"
              >
                <span class="text-xs font-normal leading-tight text-red-500"
                  >Verification failed, try again.</span
                >
              </div>
              <div
                v-if="chat.status === ChatStatus.NOCREDIT"
                class="align-center flex w-full flex-col items-center justify-center"
              >
                <span class="mb-3 text-xs font-normal leading-tight text-red-500">
                  {{ noCreditMessage }}
                </span>
                <div v-if="!hasMoreCredits" class="flex w-full items-center justify-center">
                  <Button
                    v-if="!isLoggedIn"
                    variant="link"
                    :data-hs-overlay="`#${AUTHMODELHS.LOGIN}`"
                    class="whitespace-nowrap py-0 text-xs sm:text-sm"
                  >
                    Login
                  </Button>
                  <Button
                    v-else-if="!(isOwner && !isFreePlan)"
                    :id="isChatJdroid ? 'get-more-credits-chat-jdroid' : 'get-more-credits-ide'"
                    variant="link"
                    :data-hs-overlay="`#${IDEVIEWMODELS.JDROIDGETMORECREDITS}`"
                    class="whitespace-nowrap py-0 text-xs sm:text-sm"
                  >
                    Get <span class="hidden sm:inline"> More</span>
                    Credits
                    <FontAwesomeIcon
                      icon="fa-arrow-right"
                      class="h-3 w-3"
                      style="font-weight: bold"
                    />
                  </Button>
                </div>
              </div>
            </div>
            <div
              v-if="isPositiveStatus(chat.status)"
              class="border-primary flex h-full w-full flex-col gap-2 overflow-auto px-[50px] pr-4"
              :class="{
                'text-mid text-[13px] font-light': !isChatJdroid
              }"
            >
              <div v-html="getMarkedResponse(chat)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isChatJdroid" class="surface-secondary-base">
      <div @submit.prevent="onSubmit" class="flex w-full flex-col gap-3 px-2 pt-3">
        <div class="relative w-full">
          <textarea
            id="jdroid-user-input-ide"
            :disabled="!canSubmit"
            class="background-primary border-primary dark:focus-text-white block w-full resize-none rounded-md border pl-2 pr-9 pt-3 text-xs font-medium leading-4 outline-none placeholder:text-gray-600 dark:placeholder-new-neutral-200"
            v-model="prompt"
            :placeholder="
              isLastChatLoading ? 'Building your query...' : 'Send a message to JDroid here...'
            "
            rows="4"
            @keyup.enter="onEnterChatBox"
          />
          <button
            :disabled="!canSubmit || isEmpty(prompt)"
            class="absolute bottom-1.5 right-0.5 flex h-8 w-8 items-center justify-center"
            type="submit"
            @click="onSubmit"
          >
            <FontAwesomeIcon
              icon="fa-solid fa-location-arrow "
              :class="['color-secondary-700 h-10 w-5', { 'text-text-quaternary': !canSubmit }]"
            />
          </button>
        </div>
      </div>
      <div
        :class="{ 'h-auto flex-nowrap': ideStore.currentMobileTab != null }"
        class="inline-flex w-full flex-wrap justify-around gap-3 overflow-auto"
      >
        <button
          v-for="(type, index) of AITypeList"
          :id="type?.ideChatId"
          :key="index"
          :disabled="isLastChatLoading"
          class="border-primary xs:px-2.5 flex h-11 w-fit items-center gap-2 px-1 py-1"
          @click="handleAi(type)"
        >
          <div class="flex flex-col items-start gap-2">
            <div class="text-xs font-medium underline">{{ type.header }}</div>
          </div>
        </button>
      </div>
    </div>

    <div v-else @submit.prevent="onSubmit" class="section-primary flex flex-col">
      <Credit :isChatJdroid="isChatJdroid" />

      <div class="relative w-full">
        <textarea
          id="jdroid-user-input-ide"
          :disabled="!canSubmit"
          class="section-primary border-primary dark:focus-text-white block w-full resize-none rounded-md border pl-3 pr-9 pt-3 text-xs leading-4 outline-none placeholder:text-border-primary dark:placeholder:text-gray-200"
          v-model="prompt"
          :placeholder="
            isLastChatLoading ? 'Building your query...' : 'Send a message to JDroid here...'
          "
          rows="4"
          @keyup.enter="onEnterChatBox"
        />
        <button
          :disabled="!canSubmit || isEmpty(prompt)"
          class="absolute bottom-1.5 right-0.5 flex h-8 w-8 items-center justify-center"
          type="submit"
          @click="onSubmit"
        >
          <FontAwesomeIcon
            icon="fa-solid fa-location-arrow "
            :class="['color-secondary-700 h-10 w-5', { 'text-text-quaternary': !canSubmit }]"
          />
        </button>
      </div>
      <p v-if="isChatJdroid" class="mt-1.5 text-center text-xs font-light text-[#5F7394]">
        JDroid is improving and may occasionally show inaccurate info. See
        <RouterLink :to="{ path: '/privacy' }" class="font-medium underline underline-offset-2">
          privacy policy</RouterLink
        >
      </p>
    </div>
  </div>
</template>

<style>
.font-monaco {
  font-family: 'monaco,Consolas,Ubuntu Mono, Menlo,source-code-pro,Courier New,monospace';
}

.gradient-text {
  background: linear-gradient(90deg, #a8bee5 0%, #087cfa 50%, #ff7231 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 2s forwards;
}
</style>
