interface IExecuteChatWithAiRequest {
  inputs?: string | undefined
  ide_content?: string | undefined
  outputs?: string | undefined
  language?: string | undefined
  task: AIType
  parameters?: { temperature?: number; max_new_tokens?: number; stream?: boolean }
  history?: Array<[string, string]>
}
interface ISendQuestionRequest {
  chatId: string
  aiPayload: string
}
interface ISetHistoryRequest {
  chatId: string
  history: ISetHistory[]
}
interface IGetChatIDRequest {
  type: string
}

import { IDECONSTANT } from '@/utils/ide'
import {
  AICHAT_TYPE,
  AIType,
  CHECKCANCELCHATERRORVAL,
  ChatStatus,
  type IChat,
  type ICredit,
  type ISetHistory
} from '@/utils/jDroid'
import { UNLIMITED_LIMIT } from '@/utils/sharedData/plans'
import { IDEVIEWTABS_MOBILE } from '@/utils/tabs'

import editorService from '@/services/ide/editor.service'
import recaptchaService from '@/services/recaptcha.service'

import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { useJdroidStore } from '@/stores/jdroid.store'
import axios from 'axios'

let controller: AbortController | null = null

/**
 * Stop the debugging and interrupt the request
 */
const stopDebugging = () => {
  if (controller) {
    controller.abort()
    controller = null

    const lastChat = useJdroidStore().lastChat as IChat
    if (lastChat) {
      lastChat.status = ChatStatus.CANCLED
    }
  }
}
/**
 * wait for ace and code editor to be initialized
 * @param count The count
 * @returns The promise
 */
const postAceAndCodeEditorInit = async (count: number = 0) => {
  return new Promise((resolve) => {
    if (count > 1200) {
      resolve(false)
    } else {
      setTimeout(() => {
        if (useAuthStore().isAppInitiated && useIdeStore().codeEditor) {
          resolve(true)
        } else {
          resolve(postAceAndCodeEditorInit(count + 1))
        }
      }, 100)
    }
  })
}
/**
 * add to editor
 * @param lastChat The last chat
 * @param response The response
 */
const addToEditor = (lastChat: IChat, response: string) => {
  if (lastChat.autoReplaceEditor && response) {
    // Regular expression to find pre code blocks
    // /<pre(.*?)>(.*?)<\/pre>/gs // Modified Regex
    const preTagRegex = /```(.*?)\n(.*?)\n```/gs // Modified Regex
    const preTagMatches = response.match(preTagRegex)

    if (preTagMatches && preTagMatches.length > 0) {
      const preMatch = preTagMatches[0] // Extract first match
      const code = preMatch.replace(/```(.*?)\n|\n```/g, '') // Extract pre

      editorService.setEditorSession(IDECONSTANT.CODE_EDITOR, code)
      useIdeStore().setCodeUpdated(true)

      editorService.onCodeEditorUpdate()
    }
  }
  lastChat.autoReplaceEditor = false
}

/**
 * Execute chat with ai - stream 🌊
 */
const executeChatWithAiStream = async () => {
  const lastChat = useJdroidStore().lastChat as IChat
  await postAceAndCodeEditorInit().then(async (res) => {
    if (res) {
      controller = new AbortController()

      let output = ''
      try {
        if (useIdeStore().outputEditor) {
          output = editorService.getEditorSession(IDECONSTANT.OUTPUT_EDITOR).getValue() || ''
        }
      } catch (error) {
        output = useIdeStore().outputHistory
      }

      await getChatID(controller)
        .then(async (chatID: string | null) => {
          lastChat.credit = null
          await getCredits(controller, lastChat)
            .then(async (credit: ICredit) => {
              useJdroidStore().setCredits(credit)

              const currentCredit: ICredit = credit

              if (
                currentCredit.quota - currentCredit.used <= 0 &&
                currentCredit.quota !== UNLIMITED_LIMIT
              ) {
                lastChat.status = ChatStatus.NOCREDIT
              } else {
                const script = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()
                const language = useIdeStore().isLanguage

                if (lastChat.ideContent == null) lastChat.ideContent = script
                if (lastChat.outputContent == null) lastChat.outputContent = output

                let url = ''
                let payload: IExecuteChatWithAiRequest | ISendQuestionRequest =
                  {} as IExecuteChatWithAiRequest

                const aiPayload: IExecuteChatWithAiRequest = {
                  inputs: lastChat.type === AIType.CHAT ? lastChat.prompt : script,
                  ide_content: lastChat.ideContent as string,
                  outputs: output,
                  task: lastChat.type,
                  language: language,
                  parameters: { stream: true }
                }
                if (!chatID) {
                  url = '/api/compiler-ai/generate-code-with-session-stream'
                  payload = aiPayload
                  payload.history = useJdroidStore().getLastFiveFormattedChat()
                } else {
                  url = '/api/ai-chat/sendQuestion'
                  payload = {
                    chatId: chatID,
                    aiPayload: JSON.stringify(aiPayload)
                  }
                }

                fetch(url, {
                  method: 'POST',
                  body: JSON.stringify(payload),
                  headers: {
                    'Content-Type': 'application/json',
                    'Kurukku-Kuri': useAuthStore().kurukkuKuri || ''
                  },
                  signal: controller?.signal
                })
                  .then(async (response) => {
                    if (!response.body) {
                      throw new Error('ReadableStream not supported in this browser.')
                    }

                    lastChat.response = ''
                    const reader = response.body.getReader()

                    const run = true
                    while (run) {
                      const { done, value } = await reader.read()
                      if (useIdeStore().codeEditor) await editorService.resizeCodeEditor()
                      if (done) {
                        if (!chatID) {
                          lastChat.aiPayload = JSON.stringify(payload)
                        }

                        lastChat.status = ChatStatus.SUCCESS
                        addToEditor(lastChat, lastChat.response as string)
                        if (useIdeStore().codeEditor) await editorService.resizeCodeEditor()
                        break
                      }

                      /* Decoded Chunk Data response */
                      const text = new TextDecoder('utf-8').decode(value, { stream: true })

                      const nullCharacterRegex = new RegExp(String.fromCharCode(0), 'g')
                      const cleanedText = text.replace(nullCharacterRegex, '')

                      lastChat.status = ChatStatus.STREAMING

                      try {
                        lastChat.response += cleanedText
                      } catch (error) {
                        lastChat.status = ChatStatus.STREAMING
                      }
                    }

                    currentCredit.used += 1
                    useJdroidStore().setCredits(currentCredit)
                    lastChat.credit = currentCredit
                  })
                  .catch((error: { response: { status: number } }) => {
                    if (error?.response?.status === 403) {
                      useAuthStore().clearRobotCheck()
                    } else if (error?.response?.status === 429) {
                      lastChat.status = ChatStatus.NOCREDIT
                      useJdroidStore().setCredits({
                        quota: useJdroidStore().quota,
                        used: useJdroidStore().quota
                      })
                    } else {
                      lastChat.status = ChatStatus.ERROR
                    }
                  })
              }
            })
            .catch((error: { response: { status: number } }) => {
              if (error?.response?.status === 403) {
                useAuthStore().clearRobotCheck()
              } else if (error?.response?.status === 429) {
                lastChat.status = ChatStatus.NOCREDIT
                useJdroidStore().setCredits({
                  quota: useJdroidStore().quota,
                  used: useJdroidStore().quota
                })
              } else {
                const err: any = error
                if (!err.includes(CHECKCANCELCHATERRORVAL)) lastChat.status = ChatStatus.ERROR
              }
            })
        })
        .catch((error: { response: { status: number } }) => {
          if (error?.response?.status === 403) {
            useAuthStore().clearRobotCheck()
          } else if (error?.response?.status === 429) {
            lastChat.status = ChatStatus.NOCREDIT
            useJdroidStore().setCredits({
              quota: useJdroidStore().quota,
              used: useJdroidStore().quota
            })
          } else {
            lastChat.status = ChatStatus.ERROR
          }
        })
    } else {
      lastChat.status = ChatStatus.ERROR
    }
  })
}

/**
 * clear chat
 */
const clearChat = () => {
  stopDebugging()
  useJdroidStore().setChatID(null)
  useJdroidStore().clearChat()
}
/**
 * clear chat on init
 */
const clearChatOnInit = () => {
  const lastChat = useJdroidStore().lastChat as IChat
  if (lastChat && lastChat.autoReplaceEditor == true && lastChat.status == ChatStatus.LOADING)
    return
  useJdroidStore().clearChat()
}

/**
 * add chat
 * @param chat IChat
 */
const addChat = async (chat: IChat) => {
  useJdroidStore().setShowJdroid(true)
  useJdroidStore().addChat(chat)
  await new Promise((resolve) => setTimeout(resolve, 100))
  editorService.resizeCodeEditor()
  await executeChatWithAiStream()
}
/**
 * init split
 * @returns null
 */

/**
 * toogle jdroid
 */
const toogleJdroid = async () => {
  useJdroidStore().setShowJdroid(!useJdroidStore().showJdroid)
  await new Promise((resolve) => setTimeout(resolve, 100))
  editorService.resizeCodeEditor()
}
/**
 * update notification key count
 */
const updateNotificationKeyCount = () => {
  if (useIdeStore().isCodeUpdated) {
    useJdroidStore().setNotificationKeyCount(useJdroidStore().notificationKeyCount + 1)
  }
}
/**
 * reset notification key count
 */
const resetNotificationKeyCount = () => {
  useJdroidStore().setNotificationKeyCount(0)
}

/**
 * set chat history
 * @param abortController The controller
 * @param chatId The chat id
 * @param lastFiveHistory The last five history
 * @returns The chat id
 */
const setChatHistory = async (
  abortController: AbortController | null = null,
  chatId: string,
  lastFiveHistory: ISetHistory[]
): Promise<string> => {
  const setHisoryPayload: ISetHistoryRequest = {
    chatId: chatId,
    history: lastFiveHistory
  }
  return await axios
    .post('/api/ai-chat/question', setHisoryPayload, {
      signal: abortController?.signal
    })
    .then(() => {
      useJdroidStore().setChatID(chatId)
      return chatId as string
    })
    .catch(() => {
      throw new Error('Error setting question')
    })
}
/**
 * get chat id
 * @param abortController The controller
 * @returns The chat id
 */
const getChatID = async (
  abortController: AbortController | null = null
): Promise<string | null> => {
  if (!useAuthStore().isUserloggedIn) {
    useJdroidStore().setChatID(null)
    return null
  }
  if (useJdroidStore().chatID != null) return useJdroidStore().chatID as string
  const payload: IGetChatIDRequest = {
    type: AICHAT_TYPE.PROJECT
  }
  return await axios
    .post('/api/ai-chat', payload, {
      signal: abortController?.signal
    })
    .then(
      (response: {
        data: {
          id: string
        }
      }) => {
        const id: string = (response.data.id as string) || ''
        const lastFiveHistory = useJdroidStore().getLastFiveChatAiPayloadHistory()
        if (lastFiveHistory.length === 0) {
          useJdroidStore().setChatID(id)
          return id as string
        }
        return setChatHistory(abortController, id, lastFiveHistory)
      }
    )
    .catch(() => {
      throw new Error('Error getting id')
    })
}
/**
 * get credit
 * @param abortController The controller
 * @param lastChat The last chat
 * @returns The credit
 */
const getCredits = async (
  abortController: AbortController | null = null,
  lastChat: IChat | null = null
) => {
  return await recaptchaService
    .callViaCaptcha()
    .then(async () => {
      return await axios
        .post('/api/compiler-ai/creditUsedToday', {
          signal: abortController?.signal
        })
        .then((response: { data: ICredit }) => {
          return response.data
        })
        .catch(() => {
          throw new Error('Error getting credit')
        })
    })
    .catch(() => {
      if (lastChat) lastChat.status = ChatStatus.NOROBOTCHECK
      throw new Error('Error getting credit')
    })
}

/**
 * Height change function
 * @param height The height
 */
const heightChangeFunction = (height: number | null = null) => {
  const jdroidComp = document.getElementById('splitJdroid')
  if (!jdroidComp) return
  if (useIdeStore().currentMobileTab === IDEVIEWTABS_MOBILE.JDROID || height === null) {
    jdroidComp.style.setProperty('max-height', '100%')
  } else jdroidComp?.style.setProperty('max-height', `${height}px`)
}

/**
 * @param chatId - take the chat Id
 * @description fetches the chat list
 */
const getChatList = async (chatId: string) => {
  useJdroidStore().setChatID(chatId)
  await axios
    .get(`/api/ai-chat/${chatId}`)
    .then((res) => {
      useJdroidStore().setShowJdroid(true)
      res.data.questions.forEach((question: any) => {
        const type = JSON.parse(question.questionPayload).type
        const inputs = JSON.parse(question.questionPayload).inputs

        const chat: IChat = {
          type: type,
          prompt: inputs,
          status: ChatStatus.SUCCESS,
          response: question.answer
        }

        useJdroidStore().addChat(chat)
      })
    })
    .catch((error) => {
      throw error
    })
}

export default {
  stopDebugging,
  clearChat,
  clearChatOnInit,
  addToEditor,
  addChat,
  toogleJdroid,
  updateNotificationKeyCount,
  resetNotificationKeyCount,
  getCredits,
  heightChangeFunction,
  getChatList
}
