import editorService from '@/services/ide/editor.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { defaultActiveTabs, useTabStore, type ActiveTab } from '@/stores/tabs.store'
import {
  ADVANCE_COMPILER_DATABASE_TABS,
  ADVANCE_COMPILER_TABS,
  HTML_TABS,
  NON_ADVANCE_COMPILER_TABS,
  SWITCHTAB_POSITION,
  TAB_BUTTON_POSITION,
  TAB_ICONS_NAMES
} from '@/utils/tabs'

import axios from 'axios'

/**
 * Updates the visibility of a specified tab by setting it as the active tab if it is not currently visible.
 * @param  tabName - The name of the tab to update visibility for.
 * @param isBoarding - checking if the boarding is activated
 */
const updateTabVisiblity = (tabName: TAB_ICONS_NAMES, isBoarding: boolean = false) => {
  const values = useTabStore().activeTabPosition.activeTab
  values.forEach((item) => {
    if (item.tabName == tabName && !item.visible) {
      useTabStore().setActiveTabPosition(
        item.position as TAB_BUTTON_POSITION,
        tabName as TAB_ICONS_NAMES
      )
    }
  })
  if (isBoarding) useIdeStore().boardingInstance?.continue()
}

/**
 * set tabs position to local storage
 * @param tabs tabs current positions
 * @param isDataBaseRoute isDataBaseRoute route value
 * @param isUserloggedIn isUserloggedIn value
 */
const setTabPosition = async (
  tabs: ActiveTab[],
  isDataBaseRoute: boolean,
  isUserloggedIn: boolean
) => {
  let values: ActiveTab[]

  // Retrieve existing tab positions
  if (isUserloggedIn) {
    values = ((await getTabPositions()) as ActiveTab[]) || []
  } else {
    values = (getTabPositionFromLocalStorage() as ActiveTab[]) || []
  }

  values = Array.isArray(values) ? values : []

  const newTabs: ActiveTab[] = []

  if (isDataBaseRoute) {
    // Filter out DB_TERMINAL and Jdroid from existing values
    const filteredValues = values?.filter((item) => item.tabName != TAB_ICONS_NAMES.DB_TERMINAL)
    newTabs.push(...filteredValues)

    // Add the DB_TERMINAL Terminal and Jdroid tabs from the current tabs
    const browserTab = tabs?.find((item) => item.tabName == TAB_ICONS_NAMES.DB_TERMINAL)

    if (browserTab) newTabs.push(browserTab)
  } else {
    // Handle non-Database route case
    const filteredValues = values?.filter((item) => item.tabName == TAB_ICONS_NAMES.DB_TERMINAL)
    newTabs.push(...filteredValues)

    // Add non-Database and non-Jdroid tabs
    const nonDatabaseTab = tabs?.filter((item) => item.tabName != TAB_ICONS_NAMES.DB_TERMINAL)
    newTabs.push(...nonDatabaseTab)
  }

  // Store the updated tabs
  if (isUserloggedIn) {
    updateTabPositions(newTabs)
  } else {
    localStorage.setItem('ide.activeTabPosition', JSON.stringify(newTabs))
  }

  editorService.resizeCodeEditor()
}

/**
 * update tab positions in db when user logged in
 * @param requestData the request date to update in db
 */
const updateTabPositions = async (requestData: ActiveTab[]): Promise<void> => {
  await axios.post('/api/user-config/idetabs', { tabs: requestData })
}

/**
 * get tab positions from db when user logged in
 * @returns activeTab Array
 */
const getTabPositions = async () => {
  try {
    const response = await axios.get('/api/user-config/idetabs')

    if (response.data?.tabs) {
      return updateTabsIfNecessary(response.data?.tabs)
    } else {
      return updateTabsIfNecessary(response.data)
    }
  } catch (error) {
    return []
  }
}

/**
 * regression 2.0 local storage value can be old and needs an update with defaultTabs from memory
 * @param tabs Old tabs from local storage to update
 * @returns Updated tabs with new added defaultActiveTabs
 */
const updateTabsIfNecessary = (tabs: ActiveTab[]): ActiveTab[] => {
  if (tabs?.length < defaultActiveTabs.length) {
    defaultActiveTabs.forEach((dTab) => {
      if (!tabs.some((tab) => tab.tabName === dTab.tabName)) {
        tabs.push(dTab)
      }
    })
    if (!useAuthStore().isUserloggedIn) {
      localStorage.setItem('ide.activeTabPosition', JSON.stringify(tabs))
    }
  }
  return tabs
}

/**
 * regression 1.0 local storage value can be parsedTabPositions.activeTab or parsedTabPositions
 * @returns return parsed values from localstorage
 */
const getTabPositionFromLocalStorage = () => {
  const getTabPositionsValuesFromLocalStorage = localStorage.getItem('ide.activeTabPosition')
  let parsedTabPositions = null
  if (getTabPositionsValuesFromLocalStorage) {
    try {
      parsedTabPositions = JSON.parse(getTabPositionsValuesFromLocalStorage)
      if (
        typeof parsedTabPositions === 'object' &&
        Object.prototype.hasOwnProperty.call(parsedTabPositions, 'activeTab')
      ) {
        return updateTabsIfNecessary(parsedTabPositions.activeTab)
      } else {
        return updateTabsIfNecessary(parsedTabPositions)
      }
    } catch (e) {
      return []
    }
  }
}

/**
 * Checks if a tab is visible on the bottom side.
 * @param tabName - current tabName
 * @param checkVisibility - determines if need to check visibility or not of tab
 * @returns - True if the tab is visible on the bottom side; otherwise, false.
 */
const checkCenterTab = (tabName?: TAB_ICONS_NAMES | null, checkVisibility = false) => {
  const allTabs = useTabStore().activeTabPosition
  const { isAdvancedCompiler, isAdvancedCompilerDatabase, isHtml } = useIdeStore()

  const relevantTabs = isAdvancedCompiler
    ? ADVANCE_COMPILER_TABS
    : isAdvancedCompilerDatabase
    ? ADVANCE_COMPILER_DATABASE_TABS
    : isHtml
    ? HTML_TABS
    : NON_ADVANCE_COMPILER_TABS
  return allTabs.activeTab.some((item: ActiveTab) => {
    const matchesTabName = tabName ? item.tabName === tabName : true
    const isCenterTab = item.position === TAB_BUTTON_POSITION.CENTER

    const isRelevantTab = relevantTabs.includes(item.tabName as TAB_ICONS_NAMES)

    if (checkVisibility) {
      return matchesTabName && isCenterTab && item.visible && isRelevantTab
    } else {
      return matchesTabName && isCenterTab && isRelevantTab
    }
  })
}

/**
 * Checks if a tab is maximized and visible.
 * @param tabName - The name of the tab to check.
 * @returns - True if the tab is maximized and visible; otherwise, false.
 */
const checkVisibileMaximize = (tabName: string) => {
  const allTabs = useTabStore().activeTabPosition

  const { isAdvancedCompiler, isAdvancedCompilerDatabase, isHtml } = useIdeStore()

  const relevantTabs = isAdvancedCompiler
    ? ADVANCE_COMPILER_TABS
    : isAdvancedCompilerDatabase
    ? ADVANCE_COMPILER_DATABASE_TABS
    : isHtml
    ? HTML_TABS
    : NON_ADVANCE_COMPILER_TABS

  return allTabs.activeTab.some((item: ActiveTab) => {
    const isVisibleMaximize =
      item.position == TAB_BUTTON_POSITION.MAXIMIZE && item.tabName === tabName && item.visible
    return isVisibleMaximize && relevantTabs.includes(item.tabName as TAB_ICONS_NAMES)
  })
}

/**
 * Checks if any active tab is visible on the right side based on the current tab position
 * and the compiler type. Filters tabs according to specific groups for advanced or non-advanced
 * compiler modes.
 * @returns - Returns `true` if any tab matching the relevant criteria is visible on the right side; otherwise, `false`.
 */
const isAnyTabVisibleOnMaximize = () => {
  const { activeTabPosition: allTabs } = useTabStore()
  const { isAdvancedCompiler, isAdvancedCompilerDatabase, isHtml } = useIdeStore()

  const relevantTabs = isAdvancedCompiler
    ? ADVANCE_COMPILER_TABS
    : isAdvancedCompilerDatabase
    ? ADVANCE_COMPILER_DATABASE_TABS
    : isHtml
    ? HTML_TABS
    : NON_ADVANCE_COMPILER_TABS

  return allTabs.activeTab.some((item: ActiveTab) => {
    const isMaximizeTabVisible = item.position === TAB_BUTTON_POSITION.MAXIMIZE && item.visible
    return isMaximizeTabVisible && relevantTabs.includes(item.tabName as TAB_ICONS_NAMES)
  })
}

/**
 * Reset Maximized tabs to it's previous position
 */
const resetMaximizedTabs = () => {
  const { activeTabPosition: allTabs } = useTabStore()
  allTabs.activeTab.forEach((tab: ActiveTab) => {
    if (tab.position === TAB_BUTTON_POSITION.MAXIMIZE) {
      tab.position = tab.previousPosition!
    }
  })
}

/**
 * Reset External Library tabs
 */
const resetExternalLibraryTabs = () => {
  const { activeTabPosition: allTabs } = useTabStore()
  allTabs.activeTab.forEach((tab: ActiveTab) => {
    if (tab.tabName === TAB_ICONS_NAMES.EXTERNAL_LIBRARIES) {
      tab.visible = false
    }
  })
}
/**
 * function to return current visible tab name right side
 * @returns tabName
 */
const checkTabNameCurrentlyVisibleOnCenter = () => {
  const { activeTabPosition: allTabs } = useTabStore()
  const foundTab = allTabs.activeTab.find((item: ActiveTab) => {
    return item.position === TAB_BUTTON_POSITION.CENTER && item.visible
  })
  return foundTab ? (foundTab.tabName as TAB_ICONS_NAMES) : undefined
}

/**
 * Opens the next tab in the sequence based on the current active tab and the type of IDE in use.
 * @param position - next or previous
 */
const switchingTab = (position: SWITCHTAB_POSITION) => {
  const { activeTabPosition: allTabs } = useTabStore()
  const { isAdvancedCompiler, isAdvancedCompilerDatabase, isHtml } = useIdeStore()

  const relevantTabs = isAdvancedCompiler
    ? ADVANCE_COMPILER_TABS
    : isAdvancedCompilerDatabase
    ? ADVANCE_COMPILER_DATABASE_TABS
    : isHtml
    ? HTML_TABS
    : NON_ADVANCE_COMPILER_TABS

  const availableTabs = [TAB_ICONS_NAMES.CODE_EDITOR, ...relevantTabs]

  let nextTabName: string = ''
  allTabs.activeTab.map((item: ActiveTab) => {
    if (item.visible) {
      const currentIndex = availableTabs.findIndex((items) => items == item.tabName)
      const newIndex = position == SWITCHTAB_POSITION.NEXT ? currentIndex + 1 : currentIndex - 1
      nextTabName = availableTabs[newIndex]
    }
  })

  if (nextTabName) {
    updateTabVisiblity(nextTabName as TAB_ICONS_NAMES)
  }
}

export default {
  setTabPosition,
  getTabPositions,
  getTabPositionFromLocalStorage,
  isAnyTabVisibleOnMaximize,
  checkVisibileMaximize,
  updateTabVisiblity,
  checkCenterTab,
  resetMaximizedTabs,
  resetExternalLibraryTabs,
  checkTabNameCurrentlyVisibleOnCenter,
  switchingTab
}
