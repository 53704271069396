// import gaService from '@/services/ga.service'
import gtmService from '@/services/gtm.service'
import aiService from '@/services/ide/ai.service'
import editorService from '@/services/ide/editor.service'
import { stopDebugging } from '@/services/ide/generateCode.service'
import functionalFeaturesService from '@/services/ide/plugin/functionalFeatures.service'
import projectTreeService from '@/services/ide/projectTree.service'
import projectsService from '@/services/ide/projects.service'
import historyService from '@/services/ide/settings/history.service'
import tabsService from '@/services/ide/tabs.service'
import uploadService from '@/services/ide/upload.service'
import wsService from '@/services/ide/ws.service'
import recaptchaService from '@/services/recaptcha.service'
import utilTabsService from '@/services/util.tabs.service'
import { useAuthStore } from '@/stores/auth.store'
import { useIdeStore } from '@/stores/ide.store'
import { usePluginStore } from '@/stores/plugin.store'
import { IDECONSTANT, LIB_ERROR, SERVER_ERROR, SYNC_ERROR } from '@/utils/ide'
import { IDESCROLLTO, TAB_ICONS_NAMES } from '@/utils/tabs'
import axios from 'axios'

let controller: AbortController | null = null

export interface IExecuteRequest {
  script?: string | null
  language: string | null
  versionIndex: number
  projectKey: string | boolean | number
  multiFile?: boolean
  libs: string[]
  mainFile?: string
  args: string | null
  stdin: string | null
  hasInputFiles: boolean
}
export interface IExecuteResponse {
  output?: string
  executionTime?: number
  memory?: number
  cpuTime?: number
  outputFiles?: string[]
}

export enum ExecuteStatus {
  Execution = 'execution'
}
/**
 * Wait for the sync to finish
 * @param count - the count
 */
const multifileWaitForSync = async (count: number = 0) => {
  if (!projectTreeService.isSyncSuccess) {
    if (count > 8) {
      editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, SYNC_ERROR)
      useIdeStore().setisCodeExecuting(false)
    } else {
      await new Promise((resolve) => setTimeout(resolve, 1000))
      multifileWaitForSync(count + 1)
    }
  } else {
    executeLogic()
  }
}
/**
 * Execute the code in interactive mode
 * @param requestData - the request data
 */

/**
 * Execute the logic
 */
const executeLogic = async () => {
  let requestData: IExecuteRequest | null = null

  let language = useIdeStore().isLanguage
  let versionIndex = useIdeStore().versionIndex
  if (useIdeStore().isPlusVersions && useIdeStore().isPlusVersionsList.length > 0) {
    if (useIdeStore().isAdvanced) {
      const index = useIdeStore().isBasicVersions.findIndex((v: string) => {
        return v === useIdeStore().isPlusVersionsList![versionIndex]
      })
      versionIndex = index > -1 ? index : versionIndex
    } else if (useIdeStore().libraries.length > 0) {
      const index = useIdeStore().isPlusVersionsList.findIndex((v: string) => {
        return v === useIdeStore().isBasicVersions![versionIndex]
      })
      if (index == -1) {
        editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, LIB_ERROR)
        useIdeStore().resetExecutionTime()
        useIdeStore().setisCodeExecuting(false)
        return
      }
    }
  }
  let script = editorService.getEditorSession(IDECONSTANT.CODE_EDITOR).getValue()

  if (useIdeStore().isBlockly) {
    language = useIdeStore().isBlocklyLanguages[useIdeStore().versionIndex]
    versionIndex = useIdeStore().isBlocklyLanguageVersions[useIdeStore().versionIndex]
  }
  if (useIdeStore().isBlockly && language === 'php') {
    script = '\n' + script + '\n'
  }

  if (useIdeStore().isAdvanced) {
    requestData = {
      language: language,
      versionIndex: versionIndex,
      projectKey: useIdeStore().projectKey,
      multiFile: true,
      libs: useIdeStore().libraries,
      mainFile: useIdeStore().project.home.substring(1),
      args: useIdeStore().args,
      stdin: useIdeStore().stdin,
      hasInputFiles: useIdeStore().isInputFiles.length > 0
    }
  } else {
    requestData = {
      script: script,
      args: useIdeStore().args,
      stdin: useIdeStore().stdin,
      language: language,
      versionIndex: versionIndex,
      libs: useIdeStore().libraries,
      projectKey: 1001,
      hasInputFiles: useIdeStore().isInputFiles.length > 0
    }
  }
  // const startTime: number = gaService.getCurrentTime()
  if (useIdeStore().interactiveMode) {
    wsService.executeInteractive(requestData)
    return
  }

  await axios
    .post('/engine/execute', JSON.stringify(requestData), {
      signal: controller?.signal,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response: { data: IExecuteResponse }) => {
      if (response.data?.output) {
        editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, response.data?.output)
        functionalFeaturesService.onCustomPluginExecuteComplete(response.data?.output as string)

        const outputHasError = aiService.checkOutputHasError(response.data?.output)

        if (outputHasError) {
          // If error, store in ideStore
          useIdeStore().setHasErrorAfterExecute(true)
        } else {
          //If no error - store in ideStore
          useIdeStore().setHasErrorAfterExecute(false)
        }
      } else {
        editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, '')
      }
      editorService.heightChangeFunction(IDECONSTANT.OUTPUT_EDITOR)

      useIdeStore().setExcecutionData(response.data)

      if (!useIdeStore().isAdvanced) {
        historyService.addToRecent()
      }
    })
    .catch((error: { response: { status: number } }) => {
      if (error?.response?.status === 403) {
        useAuthStore().clearRobotCheck()
      }
      if (useIdeStore().isCodeExecuting) {
        editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, SERVER_ERROR)
      }
      useIdeStore().resetExecutionTime()
    })
    .finally(() => {
      useIdeStore().setisCodeExecuting(false)
      // gaService.calculateAndSendExecuteEndTime(startTime, language, 'execute')
    })
  projectsService.autoSave()
}
/**
 * Execute the code
 */
const execute = async () => {
  if (useIdeStore().interactiveMode) {
    useIdeStore().resetStdin()
  }
  if (useIdeStore().isAdvanced) {
    projectTreeService.syncBeforeExecute()
    await new Promise((resolve) => setTimeout(resolve, 1000))
    multifileWaitForSync()
  } else {
    executeLogic()
  }
}
/**
 * Wait for the output editor to be available
 * @param count - the count
 */
const postTryExecute = async (count: number = 0) => {
  if (count < 200 && useIdeStore().isIdeView && useIdeStore().outputEditor === null) {
    await new Promise((resolve) => setTimeout(resolve, 10))
    await postTryExecute(count + 1)
  } else await new Promise((resolve) => setTimeout(resolve, 500))
}

/**
 * Check the code execution count and return a corresponding message.
 * @param count - The count of code executions.
 * @returns  The message corresponding to the count.
 */
const checkCodeExecution = (count: number) => {
  switch (true) {
    case count < 5:
      return 'code execution less than 5'
    case count >= 5 && count < 10:
      return 'code execution 5-10'
    case count >= 10:
      return 'code execution 10 and above'
  }
}

/**
 * Store the total execution time
 * @returns An object containing the message and count of code executions.
 */
const storeTotalExecution = () => {
  let count = Number(sessionStorage.getItem(ExecuteStatus.Execution) ?? 0)
  count++
  const codeExecutedTrack = checkCodeExecution(count)

  sessionStorage.setItem(ExecuteStatus.Execution, count.toString())
  return { message: codeExecutedTrack, count: count }
}

/**
 * Try to execute the code
 */
const tryExecute = async () => {
  if (useIdeStore().isCodeExecuting) return
  controller = new AbortController()
  const trackExecution = storeTotalExecution()
  if (useIdeStore().isIdeView) gtmService.sentEvent({ event: 'execute', ...trackExecution })

  useIdeStore().ideFullScreen = false
  tabsService.updateTabVisiblity(TAB_ICONS_NAMES.IO)
  await new Promise((resolve) => setTimeout(resolve, 100))
  utilTabsService.scrollToOutputEditor(IDESCROLLTO.OUTPUT)
  useIdeStore().setisCodeExecuting(true)
  useIdeStore().resetExecutionTime()
  useIdeStore().setOutputFiles([])
  stopDebugging()

  await postTryExecute()

  editorService.setEditorSession(
    IDECONSTANT.OUTPUT_EDITOR,
    'JDoodle in Action.... Running the program...'
  )

  recaptchaService
    .callViaCaptcha()
    .then(() => {
      if (usePluginStore().pluginHasFiles && !usePluginStore().pluginIsFileUploaded) {
        if (!usePluginStore().isCusomPlugin && !usePluginStore().isClientkey) {
          editorService.setEditorSession(
            IDECONSTANT.OUTPUT_EDITOR,
            'data-client-id is missing in the plugin script for getFiles feature.'
          )
          useIdeStore().resetExecutionTime()
          useIdeStore().setisCodeExecuting(false)
        } else if (usePluginStore().isCusomPlugin && !usePluginStore().isGetFiles) {
          editorService.setEditorSession(
            IDECONSTANT.OUTPUT_EDITOR,
            'This getFiles feature is not configured for this customized plugin.'
          )
          useIdeStore().resetExecutionTime()
          useIdeStore().setisCodeExecuting(false)
        } else {
          uploadService
            .uploadPluginFile()
            .then(() => {
              execute()
            })
            .catch(() => {
              editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, SERVER_ERROR)
              useIdeStore().resetExecutionTime()
              useIdeStore().setisCodeExecuting(false)
            })
        }
      } else {
        execute()
      }
    })
    .catch(() => {
      useIdeStore().setisCodeExecuting(false)
      editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, SERVER_ERROR)
      useIdeStore().resetExecutionTime()
    })
}
/**
 * stop execute the code
 */
const stopExecution = () => {
  if (!useIdeStore().isCodeExecuting) return

  if (useIdeStore().interactiveMode) {
    wsService.stopExecuteInteractive()
  } else {
    controller?.abort()
    controller = null
  }
  editorService.setEditorSession(IDECONSTANT.OUTPUT_EDITOR, 'Execution Stopped...')
  useIdeStore().resetExecutionTime()
  useIdeStore().setisCodeExecuting(false)
}

export default { tryExecute, stopExecution }
